import React from "react";
import { Row, Col } from "antd";
import "./FormPage6.css";
import { ReactComponent as MailIcon } from "../../../Assest/Image/email.svg";
import { ReactComponent as WebIcon } from "../../../Assest/Image/browser.svg";
import { ReactComponent as FacebookIcon } from "../../../Assest/Image/facebook.svg";
const Footer = () => {
  return (
    <>
      <Row gutter={24} className="customFooter">
        <Col
          md={3}
          sm={8}
          xs={24}
          className="textCenter margin10 colCustomFooter"
        >
          <h4>Casa Grande</h4>
          <p>1968 N. Peart Road, Suite 20 Casa Grande, Az 85122</p>
        </Col>
        <Col
          md={3}
          sm={8}
          xs={24}
          className="textCenter margin10 colCustomFooter"
        >
          <h4>Chandler</h4>
          <p>2051 W. Chandler Blvd, Ste 5 Chandler, Az 85224</p>
        </Col>
        <Col
          md={3}
          sm={8}
          xs={24}
          className="textCenter margin10 colCustomFooter"
        >
          <h4>Gilbert</h4>
          <p>3303 S. Lindsay Road., Ste 124 Gilbert, Az 85297</p>
        </Col>
        <Col
          md={3}
          sm={8}
          xs={24}
          className="textCenter margin10 colCustomFooter"
        >
          <h4>Glendale</h4>
          <p>17215 N. 72nd Dr. Bldg D, Ste 140A Glendale, Az 85308</p>
        </Col>
        <Col
          md={3}
          sm={8}
          xs={24}
          className="textCenter margin10 colCustomFooter"
        >
          <h4>Scottsdale</h4>
          <p>7301 E. 2nd Street, Ste 308 Scottsdale, Az 85251</p>
        </Col>
        <Col
          md={3}
          sm={8}
          xs={24}
          className="textCenter margin10 colCustomFooter"
        >
          <h4>San Tan Valley</h4>
          <p>36457 N. Gantzel Road., Ste 101 San Tan Valley, Az 85140</p>
        </Col>
        <Col md={6} xs={24}>
          <Row className="lastCellFooter height100">
            <Col
              md={24}
              sm={8}
              xs={24}
              className="flex justifyCenter footerWeb"
            >
              <div className="mb-1">
                <p>Enticare</p>
              </div>
              <FacebookIcon />
            </Col>
            <Col
              md={24}
              sm={8}
              xs={24}
              className="flex justifyCenter footerWeb"
            >
              <div className="mb-1">
                <p>enticare.com</p>
              </div>
              <WebIcon />
            </Col>
            <Col
              md={24}
              sm={8}
              xs={24}
              className="flex justifyCenter footerWeb"
            >
              <div className="mb-1">
                <p>info@enticare.com</p>
              </div>
              <MailIcon />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="customFooter">
        <Col md={18} xs={24} className="textCenter lastFooter">
          <p>Phone (480) 214-9000 Option 2 | Fax (480) 214-9999</p>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
