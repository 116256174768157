import React from 'react'
import {ReactComponent as MailIcon } from "../../../Assest/Image/email.svg";
import {ReactComponent as WebIcon } from "../../../Assest/Image/browser.svg";
import {ReactComponent as FacebookIcon } from "../../../Assest/Image/facebook.svg";
import { Row, Col } from 'antd';
const FormSection8 = () => {
  return (
    <div className=' authFooter '>
    <Row gutter={[16, 16]}>
        <Col  xs={24} sm={12} md={6}>
           <div className='hardCenter textCenter'>Phone (480) 214-9000 Option 2 | Fax (480) 214-9999</div>
        </Col>
        <Col  xs={24} sm={12} md={6}>
            <div className='hardCenter'><MailIcon /><div className='mb-1'>info@enticare.com</div></div>
        </Col>
        <Col  xs={24} sm={12} md={6}>
          <div className='hardCenter'><WebIcon/> <div className='mb-1'>enticare.com</div></div>
        </Col>
        <Col  xs={24} sm={12} md={6}>
               <div className='hardCenter'><FacebookIcon/> <div className='mb-1'>Enticare</div></div>
        </Col>
      </Row>
           
          
            
       
    </div>

  )
}

export default FormSection8;