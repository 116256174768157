import { useEffect, useState } from "react";

const FormSection6 = ({ data, setData, reviewSymptoms, setReviewSymptoms, checkValues }) => {
  // const [reviewSymptoms, setReviewSymptoms] = useState(reviewSymptomsArray);

  const handleSelectionChange1 = (id, value) => {
    const updatedMultiChoice = reviewSymptoms.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          selected: value,
        };
      }
      return item;
    });

    setReviewSymptoms(updatedMultiChoice);
  };

  // function filterArray(array) {
  // 	return array.map(({ condition, selected }) => ({ condition, selected }));
  // }

  useEffect(() => {
    // const filteredArray = filterArray(reviewSymptoms);

    setData((prevState) => ({
      ...prevState,
      review_of_symptoms1: [...reviewSymptoms],
    }));
  }, [reviewSymptoms, setData]);

  return (
    <>
      <div style={{ marginTop: "2%" }}>
        <h3 className="fs5Heading">REVIEW OF SYMPTOMS:</h3>
        <p className="fs5TranslationText">¿REVISIÓN DE SÍNTOMAS?</p>
        <p className="fs5SubHeading">
          Do you <span className="fs5BoldText">NOW</span> have any of the
          following symptoms?
        </p>
        <p className="fs5TranslationText">
          ¿AHORA tiene alguno de los siguientes síntomas?
        </p>
      </div>
      {reviewSymptoms.map((item, index) => {
        return (

          <div className="fs5RadioContainer" key={index}>
            <h3 className="fs5RadioHeading">
              {item.condition}
              <span className="fs5RadioHeadingTranslation">
                {item.translation}
              </span>
            </h3>
            <div className="fs5CheckboxContainer">
              <div className={`flex flex-wrap ml-2 ${checkValues && !data?.review_of_symptoms1[index].selected ? "missingField" : ""} `} style={{ width: "95%" }}>
                <div>
                  <div className="radio-main">
                    <input
                      type="radio"
                      name={"review_symptoms" + item.id}
                      className="radio-custom"
                      id={"fs6yes" + item.id}
                      value={"Yes"}
                      onChange={() => handleSelectionChange1(item.id, "Yes")}
                      checked={item.selected === "Yes"}
                    />
                    <label htmlFor={"fs6yes" + item.id}>
                      Yes <span className="italics ">Si</span>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="radio-main">
                    <input
                      type="radio"
                      name={"review_symptoms" + item.id}
                      className="radio-custom"
                      id={"fs6no" + item.id}
                      value={"No"}
                      onChange={() => handleSelectionChange1(item.id, "No")}
                      checked={item.selected === "No"}
                    />
                    <label htmlFor={"fs6no" + item.id}>
                      No <span className="italics ">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        );
      })}
    </>
  );
};

export default FormSection6;
