import { useEffect, useState } from 'react';
import FormSection5 from './FormSection5';
import '../FormSections.css';
import './FormPage3.css';
const FormPage3 = ({ data, setData, checkValues }) => {
	// const [data, setData] = useState({});

	return (
		<div className="formPage3Main">
			<FormSection5 setData={setData} data={data} checkValues={checkValues} />
		</div>
	);
};

export default FormPage3;
