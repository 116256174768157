import React, { useEffect, useState } from "react";
import "./FormPage5.css";
import "../FormSections.css";
import FormSection8 from "./FormSection8";
import { Row, Col, DatePicker } from "antd";
import SignatureField from "../../SignatureField/SignatureField";
const FormPage5 = ({ data, setData, checkValues }) => {
  const handleChange = (e, Name, noValue) => {
    const key = noValue ? Name : e?.target?.name;
    const value = noValue ? e : e?.target?.value;
    setData({
      ...data,
      patient_auth: {
        ...data.patient_auth,
        [key]: value,
      },
    });
  };

  return (
    <div className="formPage5Main ">
      <h3 className="heading">
        PATIENT AUTHORIZATION FOR USE AND DISCLOSURE OF PROTECTED HEALTH
        INFORMATION{" "}
      </h3>
      <p className="mt-2 marginTop3">
        Enticare P.C. takes your privacy seriously. We will not disclose your
        medical records (protected health information) to any party without your
        signed consent, except as stipulated in our Notice of Privacy Practices.
      </p>
      <div className="patientInfo  ">
        <Row gutter={16}>
          <Col md={14} sm={12} xs={24} className="flex marginTop3 inputParent ">
            {" "}
            Name:
            <input
              type="text"
              name="patient_name"

              className={`ml-1 w-100 ${checkValues && !data?.patient_auth?.patient_name ? "missingInputField" : ""}`}
              onChange={(e) => handleChange(e, "patient_name")}
              value={data?.patient_auth?.patient_name}
            />
          </Col>
          <Col md={7} sm={12} xs={24} className="flex marginTop3 inputParent ">
            Date:
            <DatePicker
              className={`specialDatePicker fs2dateStyle ${checkValues && !data?.patient_auth?.auth_date ? "missingInputField" : ""}`}
              onChange={(date, dateString) =>
                handleChange(dateString, "auth_date", true)
              }
              name="auth_date"
            // value={data?.patient_auth?.auth_date.dateObject}
            />
          </Col>
        </Row>
        <div className="flex titleMain"></div>
        <div className="fs5Auth marginTop2">
          <h3 className="mt-2">
            AUTHORIZATION TO DISCLOSE PROTECTED HEALTH INFORMATION INCLUDING HIV
            & AIDS RELATED INFORMATION
          </h3>
          <ul>
            <li className="">
              I understand that neither Provider nor Recipient may condition
              treatment, payment, enrollment or eligibility for benefits on
              whether I sign this Authorization. In addition, I understand that
              Recipient may re-disclose the Records and that the Records may no
              longer be protected by the Federal privacy regulations.
            </li>
            <li className="">
              I acknowledge and agree that the protected health information
              authorized to be disclosed under this Authorization may include
              records for drug or alcohol abuse or psychiatric illness, and
              records of testing, diagnosis or treatment for HIV, HIV-related
              diseases and communicable diseaserelated information
            </li>
            <li>
              With respect to any communicable disease-related information
              protected by State confidentiality rules and disclosed under this
              Authorization, Recipient is prohibited from making any further
              disclosure of this information unless further disclosure is
              expressly permitted by me pursuant to a separate written
              authorization or is otherwise permitted by applicable law
            </li>
            <li>
              Further, with respect to any drug and alcohol abuse treatment
              information disclosed under this Authorization, this information
              has been disclosed from records protected by Federal
              confidentiality rules (42 C.F.R. Part 2). The Federal rules
              prohibit the recipient of this information from making any further
              disclosure of this information unless further disclosure is
              expressly permitted by me pursuant to a separate written
              authorization or is otherwise permitted by 42 C.F.R. Part 2. A
              general authorization for the release of medical or other
              information is NOT sufficient for this purpose. The Federal rules
              restrict any use of the information to criminally investigate or
              prosecute any alcohol or drug abuse patient
            </li>
          </ul>
          <h3 className="mt-2">RIGHT OF REFUSAL</h3>
          <ul>
            <li>
              I acknowledge that I have had the opportunity to review Enticare
              P.C.’s Notice of Privacy Practices, which is displayed for public
              inspection at its facility and on its website. This Notice
              describes how my protected health information may be used and
              disclosed, and how I may access my health records.
            </li>
            <li>
              I understand that I have the right to refuse to sign this
              authorization and that I do not have to sign this authorization to
              receive treatment at Enticare P.C. When my information is used or
              disclosed pursuant to this authorization, it may be subject to
              re-disclosure by the recipient and may no longer be protected by
              the Federal Health Insurance Portability and Accountability Act
              (HIPAA). I have the right to revoke this authorization in writing
              except to the extent that the practice has acted in reliance upon
              this authorization. My written revocation must be submitted to the
              Enticare P.C. Privacy Officer at 3420 S. Mercy Road, Suite 107
              Gilbert, AZ 85297.
            </li>
          </ul>
          <h3 className="mt-2">EXPIRATION</h3>
          <p className="mt-2">
            This Authorization will remain effective until the expiration date
            specified below or, if no date is set forth below, for one-year
            following the date of this signing, at which time this Authorization
            will expire. A photocopy of this Authorization will be considered
            effective and valid as the original.
          </p>
        </div>
        <div>
          <Row gutter={16}>
            <span className="inputParent marginLeft8 marginTop3">
              Date authorization expires (if any):
            </span>
            <Col
              md={12}
              sm={12}
              xs={24}
              className="flex marginTop3 inputParent"
            >
              <DatePicker

                className={`specialDatePicker fs2dateStyle ${checkValues && !data?.patient_auth?.auth_expiry ? "missingInputField" : ""}`}
                name="auth_expiry"
                onChange={(date, dateString) =>
                  handleChange(dateString, "auth_expiry", true)
                }

              />

            </Col>
          </Row>

          <Row gutter={16}>
            <span className="marginBottomCustom inputParent marginLeft8 marginTop3 signText">Signed by:</span>
            <Col sm={12} xs={24} className="marginTop3">
              <SignatureField
                name={"auth_signature"}
                onChange={handleChange}
                value={data?.patient_auth?.auth_signature}
              />
              <div className={`borderTop  ${checkValues && !data?.patient_auth?.auth_signature?.signature ? "missingFieldSpecial" : ""}`}>
                Signature of Patient or Legal Guardian
              </div>
            </Col>
            <Col
              md={6}
              sm={12}
              xs={24}
              className="marginTop3 signSibling inputParent"
            >
              <DatePicker

                className={`specialDatePicker fs2dateStyle ${checkValues && !data?.patient_auth?.auth_current_date ? "missingInputField" : ""}`}
                name="auth_current_date"
                onChange={(date, dateString) =>
                  handleChange(dateString, "auth_current_date", true)
                }

              />
              <div className="w-100 "> Today's Date</div>
            </Col>
          </Row>
          <Row className=" marginCustom" gutter={16}>
            <span className="marginLeft8 marginTop3 hide remove">
              Signed by:
            </span>
            <Col sm={12} xs={24}>
              <input
                type="text"
                name="auth_relation"

                className={`className="" ${checkValues && !data?.patient_auth?.auth_relation ? "missingInputField" : ""}`}
                onChange={(e) => handleChange(e, "auth_relation")}
                value={data?.patient_auth?.auth_relation}
              />
              <div className="w-100 ">Relationship to Patient</div>
            </Col>
          </Row>
          <FormSection8 />
        </div>
      </div>
    </div>
  );
};

export default FormPage5;
