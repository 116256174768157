import { useEffect, useState } from 'react';
import FormSection1 from './FormSection1';
import FormSection2 from './FormSection2';
import FormSection3 from './FormSection3';
import FormSection4 from './FormSection4';
import { Form } from 'antd';
import Images from '../../../Assest/Image';
import './FormPage1.css';

const FormPage1 = ({ data, setData, checkValues }) => {
	return (
		<div className="formPage1MainContainer">
			<div className="formPage1Header">
				<div className="formPage1HeaderLeft">
					<div className="ml-2" style={{ width: '150px' }}>
						<img src={Images.logo} alt="logo" width={'100%'} height={'100%'} />
					</div>
				</div>
				<div className="formPage1HeaderRight">
					<h1>NEW PATIENT PACKET</h1>
				</div>
			</div>

			<Form>
				<FormSection1 setData={setData} data={data} checkValues={checkValues} />
				<FormSection2 setData={setData} data={data} checkValues={checkValues} />
				<FormSection3 setData={setData} data={data} checkValues={checkValues} />
				<FormSection4 setData={setData} data={data} checkValues={checkValues} />
			</Form>
		</div>
	);
};

export default FormPage1;
