import { DatePicker, Radio } from "antd";
import { useEffect, useState } from "react";
import { calculateAge, disabledDate } from "../../../utils/functions";
const genderArray = [
  { value: "Male", label: "Male", translation: "Hombre" },
  { value: "Female", label: "Female", translation: "Mujer" },
];

const maritialStatusArray = [
  { value: "Single", label: "Single", translation: "" },
  { value: "Married", label: "Married", translation: "" },
  { value: "Divorced", label: "Divorced", translation: "" },
  { value: "Widowed", label: "Widowed", translation: "" },
  { value: "Other", label: "Other", translation: "" },
];

const appointmentRemindersArray = [
  {
    label: "Home Phone",
    value: "homephone",
    translation: "Teléfono de casa",
  },
  {
    label: "Cell Phone",
    value: "cellphone",
    translation: "Teléfono movil",
  },
  {
    label: "Text",
    value: "text",
    translation: "Texto",
  },
  {
    label: "Email",
    value: "email",
    translation: "Correo electronico",
  },
];

const FormSection2 = ({ data, setData, checkValues }) => {
  const [value, setValue] = useState("");


  const handleOnChangeGender = (e) => {
    setValue(e.target.value);
  };
  const handleMainFieldsChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      patient_info: { ...prevState.patient_info, [name]: value },
    }));
  };
  const handleChangeMailingAddress = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      patient_info: {
        ...prevState.patient_info,
        mailing_address: {
          ...prevState.patient_info.mailing_address,
          [name]: value,
        },
      },
    }));
  };
  const handleChangeOtherAddress = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      patient_info: {
        ...prevState.patient_info,
        other_address: {
          ...prevState.patient_info.other_address,
          [name]: value,
        },
      },
    }));
  };
  const onChangeDate = (date, dateString) => {
    setData((prevState) => ({
      ...prevState,
      patient_info: {
        ...prevState.patient_info,
        DOB: dateString,
        age: calculateAge(dateString),
      },
    }));
  };



  return (
    <>
      <div className="fs2MainContainer">
        <h1 className="fs2Heading">PATIENT INFORMATION:</h1>
        <p className="fs2TranslateHeading">NFORMACION DEL PACIENTE</p>

        <div className="fs2Conatiner">
          <div className="flex fs2InputConatiner" style={{ width: "50%" }}>
            <div className="fs2InputConatinerHead endingItem" style={{ width: "150px" }}>
              <h3>Patient Name:</h3>
              <p className="italics">Nombre del paciente</p>
            </div>
            <input type="text" name="patient_name" onChange={handleChange}
              className={`${checkValues && !data.patient_info.patient_name ? "missingInputField" : ""}`} />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "30%" }}
          >
            <div
              className="fs2InputConatinerHead fs3labels endingItem"
              style={{ width: "150px" }}
            >
              <h3>DOB</h3>
              <p className="italics">Fecha de nacimiento</p>
            </div>
            <DatePicker
              className={`${checkValues && !data.patient_info.DOB ? "missingInputField" : ""} fs2dateStyle`}
              onChange={onChangeDate}
              name="DOB"
              disabledDate={disabledDate}

            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin "
            style={{ width: "50%" }}
          >
            <div className="fs2InputConatinerHead endingItem " style={{ width: "50px" }}>
              <h3>Age:</h3>
              <p className="italics">Edad</p>
            </div>
            <input
              min={0}
              value={calculateAge(data.patient_info.DOB)}
              type="number" name="age" className={`${checkValues && data.patient_info.age === "" ? "missingInputField" : ""} numberInput disabledSection`} />
          </div>
        </div>

        <div className="fs2Conatiner">
          <div className="fs2CheckboxConatiner fs2CheckboxConatinerMargin">
            <div className="fs2SubHeading endingItem">
              <p>Sex:</p>
              <p className="italics">Sexo</p>
            </div>
            <div className={`${checkValues && !data.patient_info.sex ? "missingField" : ""} flex flex-wrap`}>
              {genderArray.map((item) => (
                <div key={item.value}>
                  <div className="radio-main">
                    <input
                      type="radio"
                      name="sex"
                      className={`${checkValues && !data.patient_info.sex ? "missingInputField" : ""} radio-custom`}
                      id={item.value}
                      value={item.value}
                      onChange={handleChange}
                    />
                    <label htmlFor={item.value}>{item.label}</label>
                  </div>
                  <p className="italics checkboxTranslate">
                    {item.translation}
                  </p>
                </div>
              ))}
            </div>
          </div>

          <div className="fs2CheckboxConatiner fs2CheckboxConatinerMargin">
            <div className="fs2SubHeading endingItem">
              <p>Marital Status:</p>
              <p className="italics">Soltero</p>
            </div>

            {/* <Radio.Group> */}
            <div className={`${checkValues && !data.patient_info.maritial_status ? "missingField" : ""} flex flex-wrap`}>
              {maritialStatusArray.map((item) => (
                <div key={item.value}>
                  <div className="radio-main">
                    <input
                      type="radio"
                      name="maritial_status"
                      className="radio-custom"
                      id={item.value}
                      value={item.value}
                      onChange={(e) => handleChange(e)}
                    />
                    <label htmlFor={item.value}>{item.label}</label>
                  </div>
                  <p className="italics checkboxTranslate">
                    {item.translation}
                  </p>
                </div>
              ))}
            </div>
            {/* </Radio.Group> */}
          </div>
        </div>

        <div className="fs2Conatiner">
          <div className="flex fs2InputConatiner" style={{ width: "45%" }}>
            <div className="fs2InputConatinerHead endingItem" style={{ width: "200px" }}>
              <h3>Mailing Address:</h3>
              <p className="italics">Dirección de envio</p>
            </div>
            <input
              type="text"
              name="address"
              onChange={handleChangeMailingAddress}
              className={`${checkValues && !data.patient_info.mailing_address.address ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "17.5%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "80px" }}>
              <h3>City:</h3>
              <p className="italics">Ciudad</p>
            </div>
            <input
              type="text"
              name="city"
              onChange={handleChangeMailingAddress}
              className={`${checkValues && !data.patient_info.mailing_address.city ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "17.5%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "80px" }}>
              <h3>State:</h3>
              <p className="italics">Estado</p>
            </div>
            <input
              type="text"
              name="state"
              onChange={handleChangeMailingAddress}
              className={`${checkValues && !data.patient_info.mailing_address.state ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "20%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "100px" }}>
              <h3>Zip</h3>
              <p className="italics">Codigo Postal</p>
            </div>
            <input
              type="number"
              name="zip"
              min={0}
              onChange={handleChangeMailingAddress}
              className={`${checkValues && !data.patient_info?.mailing_address.zip ? "missingInputField" : ""}`}
            />
          </div>
        </div>

        <div className="fs2Conatiner">
          <div className="flex fs2InputConatiner " style={{ width: "45%" }}>
            <div className="fs2InputConatinerHead endingItem" style={{ width: "200px" }}>
              <h3>Other Address:</h3>
              <p className="italics">Otra dirección</p>
            </div>
            <input
              type="text"
              name="address"
              onChange={handleChangeOtherAddress}
              className={`${checkValues && !data.patient_info?.other_address.address ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "17.5%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "80px" }}>
              <h3>City:</h3>
              <p className="italics">Ciudad</p>
            </div>
            <input
              type="text"
              name="city"
              onChange={handleChangeOtherAddress}
              className={`${checkValues && !data.patient_info?.other_address.city ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "17.5%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "80px" }}>
              <h3>State:</h3>
              <p className="italics">Estado</p>
            </div>
            <input
              type="text"
              name="state"
              onChange={handleChangeOtherAddress}
              className={`${checkValues && !data.patient_info?.other_address.state ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin "
            style={{ width: "20%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "100px" }}>
              <h3>Zip</h3>
              <p className="italics">Codigo Postal</p>
            </div>
            <input type="number" name="zip" onChange={handleChangeOtherAddress}
              className={`${checkValues && !data.patient_info?.other_address.zip ? "missingInputField" : ""}`}
              min={0}
            />
          </div>
        </div>

        <div className="fs2Conatiner">
          <div className="flex fs2InputConatiner" style={{ width: "33%" }}>
            <div className="fs2InputConatinerHead endingItem" style={{ width: "150px" }}>
              <h3>Home Phone:</h3>
              <p className="italics">Teléfono de casa</p>
            </div>
            <input type="number" name="home_phone" onChange={handleMainFieldsChange}
              className={`${checkValues && !data?.home_phone ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "33%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "150px" }}>
              <h3>Cell Phone:</h3>
              <p className="italics">Teléfono movil</p>
            </div>
            <input type="number" name="cell_phone" onChange={handleMainFieldsChange}
              className={`${checkValues && !data?.cell_phone ? "missingInputField" : ""}`}
              min={0}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "33%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "150px" }}>
              <h3>Work Phone:</h3>
              <p className="italics">Teléfono de trabajo</p>
            </div>
            <input type="number" name="work_phone" onChange={handleMainFieldsChange}
              className={`${checkValues && !data?.work_phone ? "missingInputField" : ""}`}
              min={0}
            />
          </div>
        </div>

        <div className="fs2Conatiner">
          <div className="fs2CheckboxConatiner fs2CheckboxConatinerLong">
            <div className="fs2SubHeading fs2SubHeadingLong endingItem">
              <h3 style={{ fontSize: "1rem" }}>
                Preferred method for appointment reminders{" "}
                <span style={{ display: "block" }}>
                  (check all that apply):
                </span>
              </h3>
              <p className="italics">
                Metodo preferido para recordatorios de citas
              </p>
            </div>

            <div className={`${checkValues && !data.preffered_method ? "missingField" : ""} flex flex-wrap fs2CheckboxConatinerLongInner`}>
              {appointmentRemindersArray.map((item) => (
                <div key={item.value}>
                  <div className="radio-main">
                    <input
                      type="radio"
                      name="preffered_method"
                      className="radio-custom"
                      id={item.value}
                      value={item.value}
                      onChange={handleMainFieldsChange}
                    />
                    <label htmlFor={item.value}>{item.label}</label>
                  </div>
                  <p className="italics checkboxTranslate">
                    {item.translation}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="fs2Conatiner">
          <div className="flex fs2InputConatiner" style={{ width: "100%" }}>
            <div className="fs2InputConatinerHead endingItem" style={{ width: "300px" }}>
              <h3>Email (print clearly): </h3>
              <p className="italics">Correo Electronico (imprima claramente)</p>
            </div>
            <input type="text" name="email" onChange={handleMainFieldsChange}
              className={`${checkValues && !data?.email ? "missingInputField" : ""}`}
            />
          </div>
        </div>
        <div className="fs2Conatiner">
          <div className="flex fs2InputConatiner" style={{ width: "40%" }}>
            <div className="fs2InputConatinerHead endingItem" style={{ width: "300px" }}>
              <h3>Pharmacy Name & City:</h3>
              <p className="italics">Nombre de farmacia y ciudad</p>
            </div>
            <input
              type="text"
              name="pharmacy_name_city"
              onChange={handleMainFieldsChange}
              className={`${checkValues && !data?.pharmacy_name_city ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "30%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "150px" }}>
              <h3>Cross Street:</h3>
              <p className="italics">Calles que cruzan</p>
            </div>
            <input type="text" name="cross_street" onChange={handleMainFieldsChange}
              className={`${checkValues && !data?.cross_street ? "missingInputField" : ""}`}
            />
          </div>

          <div
            className="flex fs2InputConatiner fs2CheckboxConatinerMargin"
            style={{ width: "30%" }}
          >
            <div className="fs2InputConatinerHead endingItem" style={{ width: "100px" }}>
              <h3>Phone:</h3>
              <p className="italics">Teléfono</p>
            </div>
            <input type="number" name="phone" onChange={handleMainFieldsChange}
              min={0}
              className={`${checkValues && !data?.phone ? "missingInputField" : ""}`}
            />
          </div>
        </div>

        <div className="divider" />
      </div>
    </>
  );
};

export default FormSection2;

