import { FormUrlComponent } from "./Components";
import "./App.css";
import { postApiWithoutAuth } from "./utils/api";
import { useEffect, useState } from "react";
import { URL } from "./utils/apiUrls";
import { Spin, Alert } from "antd";
import Favicon from "./Assest/Image/logo.png";
const array = {
  start: {
    reason_for_visit: "",
    date: "",
    relationship_to_patient: "",
    legal_guradian: "",
  },

  patient_info: {
    patient_name: "",
    DOB: "",
    age: "",
    sex: "",
    maritial_status: "",
    mailing_address: {
      address: "",
      city: "",
      state: "",
      zip: "",
    },
    other_address: {
      address: "",
      city: "",
      state: "",
      zip: "",
    },
    home_phone: "",
    cell_phone: "",
    work_phone: "",
    preffered_method: "",
    email: "",
    pharmacy_name_city: "",
    cross_street: "",
    phone: "",
  },

  referral_info: {
    referring_facility: "",
    address: "",
    referring_provider_name: {
      name: "",
      phone: "",
    },
    primarycare_provider_name: {
      name: "",
      phone: "",
    },
    self_pay: false,
    responsible_party_guardian: {
      guardian: "",
      phone: "",
    },
    primary_insurance_detail: {
      primary_insurance_co: "",
      phone: "",
      policy_holder_name: "",
      group: "",
      relationship_to_patient: "",
      dob: "",
    },
    secondary_insurance_detail: {
      secondary_insurance_co: "",
      phone: "",
      policy_holder_name: "",
      group: "",
      relationship_to_patient: "",
      dob: "",
    },
  },

  federal_privacy_standards: {
    race: "",
    ethnicity: "",
    preffered_language: "",
  },

  hippa_approved_contacts: {
    contact1: {
      name: "",
      DOB: "",
      phone: "",
      relationship_to_patient: "",
    },
    contact2: {
      name: "",
      DOB: "",
      phone: "",
      relationship_to_patient: "",
    },
  },
  emergency_contact: {
    contact1: {
      name: "",
      DOB: "",
      phone: "",
      relationship_to_patient: "",
    },
  },

  height: "",
  weight: "",

  medications: {
    none: false,
    list: [],
  },

  allergies: {
    none: false,
    allergic_to: "",
    medications_allergic_to: [],
  },

  surgery_history: {
    none: false,
    have_anesthesia: "",
    explain: "",
    procedure: [], // HAVE OBJECTS LIKE { procedure: "", date: "" },
  },

  family_history: {
    none: false,
    conditions_relationships: [],
  },
  social_history: {
    none: false,
    smoker_type: "",
    former_smoker: {
      how_long_did_you_somke: "",
      when_did_you_quit: "",
    },
    current_smoker: {
      how_many_packs_per_day_do_you_smoke: "",
      how_long_have_you_smoked: "",
      interested_in_quitting: "",
      do_you_drink: "",
      how_often_do_you_drink: "",
    },
  },
  recreational_drug_use: {
    used: "",
    drug: "",
    last_used: "",
    female_only: "",
  },

  medical_history: [],
  review_of_symptoms1: [],
  review_of_symptoms2: [],

  eustachian_tube_dysfunction: {
    list: [],
    disfunctions_in: "",
    totalScore: "",
    itemScore: "",
  },

  the_epworth_sleepiness_scale: [],

  hearing_history_questionnaire: [],
  allergy_history_questionnaire: {
    questions: [],
    perscriptions: [], // OBJECT LIKE { perscription: "", long: "" },
    list: [],
  },
};

function App({ handleSubmit }) {
  const [data, setData] = useState(array);
  const [isLoading, setisLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [checkValues, setCheckValues] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const handleButtonClick = () => {
    setShowAlert(true);

    // Automatically hide the alert after 3 seconds
    setTimeout(() => {
      setShowAlert(false);
    }, 3000);
  };


  const sendAllData = async () => {
    setisLoading(true);
    const response = await postApiWithoutAuth(URL?.getFormData, data);
    if (response?.status === 200) {
      setisLoading(false);
      handleSubmit();

    } else {
      setisLoading(false);
    }
  };
  const updateFavicon = () => {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = Favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
  };
  useEffect(() => {
    updateFavicon();
  }, [])

  useEffect(() => {
    let isDisabled = false;

    if (data.hippa_approved_contacts.none === true) {
      isDisabled = false;
    } else {

      if (data?.hippa_approved_contacts?.contact1?.name === "" || data?.hippa_approved_contacts?.contact1?.DOB === "" || data?.hippa_approved_contacts?.contact1?.phone === "" || data?.hippa_approved_contacts?.contact1?.relationship_to_patient === "") {
        isDisabled = true;
      } else {
        isDisabled = false;

      }
    }

    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (data?.allergies?.none === true) {
      isDisabled = false
    } else {
      isDisabled = true

      if (data?.allergies?.medications_allergic_to?.length && data?.allergies?.medications_allergic_to[0] !== "" && data?.allergies?.allergic_to) {
        isDisabled = false;
      } else {
        isDisabled = true;
      }

    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }





    if (data?.allergy_history_questionnaire?.none !== true) {
      for (let i = 0; i < data?.allergy_history_questionnaire?.list?.length; i++) {
        const element = data?.allergy_history_questionnaire?.list[i];
        if ((element.chanceOfDozing === "")) {
          isDisabled = true;
          break;
        } else {
          isDisabled = false;
        }
      }

      if (isDisabled === true) {
        setDisabled(true)
        return;
      }

      let firstPrescription = data?.allergy_history_questionnaire?.perscriptions[0];
      if (firstPrescription.perscription === "" || firstPrescription.duration === "") {
        isDisabled = true;
      } else {
        isDisabled = false;
      }





      if (isDisabled === true) {
        setDisabled(true)
        return;
      }


      for (let i = 0; i < data?.allergy_history_questionnaire?.questions.length; i++) {
        const element = data?.allergy_history_questionnaire?.questions[i];
        if (element.response === "" && data?.allergy_history_questionnaire?.none !== true) {
          isDisabled = true;
          break;
        } else {
          isDisabled = false;
        }
      }

      if (isDisabled === true) {
        setDisabled(true)
        return;
      }

    } else {
      isDisabled = false;
    }



    if (
      data?.federal_privacy_standards?.ethnicity &&
      data?.federal_privacy_standards?.race &&
      data?.federal_privacy_standards?.preffered_language &&
      data?.emergency_contact?.contact1?.name &&
      data?.emergency_contact?.contact1?.DOB &&
      data?.emergency_contact?.contact1?.phone &&
      data?.emergency_contact?.contact1?.relationship_to_patient &&
      data?.height &&
      data?.weight
    ) {
      isDisabled = false
    } else {
      isDisabled = true
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (data?.eustachian_tube_dysfunction?.disfunctions_in && data?.eustachian_tube_dysfunction?.itemScore !== "") {
      isDisabled = false
    } else {
      isDisabled = true
    }

    if (isDisabled === true) {
      setDisabled(true)
      return;
    }
    for (let i = 0; i < data?.eustachian_tube_dysfunction?.list.length; i++) {
      const element = data?.eustachian_tube_dysfunction?.list[i];
      if (element.selected === null || element.selected === undefined) {
        isDisabled = true;
        break;
      } else {
        isDisabled = false;
      }
    }

    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (data?.family_history?.none !== true) {
      for (let i = 0; i < data?.family_history?.conditions_relationships.length; i++) {
        const item = data?.family_history?.conditions_relationships[i];
        if (item.relationship === "") {
          isDisabled = true;
          break;
        } else {
          isDisabled = false;
        }
      }
    } else {
      isDisabled = false;
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    for (let i = 0; i < data?.hearing_history_questionnaire.length; i++) {
      const element = data?.hearing_history_questionnaire[i];
      if (element.chanceOfDozing === "") {
        isDisabled = true;
        break;
      } else {
        isDisabled = false;
      }
    }

    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    for (let i = 0; i < data?.medical_history?.length; i++) {
      const element = data?.medical_history[i];
      if (!element.selected) {
        isDisabled = true;
        break;
      } else {
        isDisabled = false;
      }
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }
    if (data?.medications?.none === true) {
      isDisabled = false
    } else {
      isDisabled = true
      if (data?.medications?.list?.length && data?.medications?.list[0] !== "") {
        isDisabled = false;
      } else {
        isDisabled = true;
      }


    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (
      data?.patient_info?.patient_name &&
      data?.patient_info?.sex &&
      data?.patient_info?.maritial_status &&
      data?.patient_info?.DOB &&
      data?.patient_info?.mailing_address?.city &&
      data?.patient_info?.mailing_address?.state &&
      data?.patient_info?.mailing_address?.zip &&
      data?.patient_info?.mailing_address?.address &&
      data?.home_phone &&
      data?.cell_phone &&
      data?.work_phone &&
      data?.preffered_method &&
      data?.email &&
      data?.pharmacy_name_city &&
      data?.cross_street &&
      data?.phone
    ) {
      isDisabled = false;
    } else {
      isDisabled = true;
    }



    if (isDisabled === true) {
      setDisabled(true)
      return;
    }



    if (data?.recreational_drug_use?.used === "Never") {
      isDisabled = false;
    } else if (data?.recreational_drug_use?.drug && data?.recreational_drug_use?.last_used && data?.recreational_drug_use?.used) {
      isDisabled = false
    } else {
      isDisabled = true
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }


    if (data?.referral_info?.none !== true) {
      if (data.referral_info && typeof data.referral_info === 'object') {
        // Check if the nested object exists and is an object type
        for (const key in data.referral_info) {
          const nestedObject = key === "self_pay" || key === "none" || key === "secondary_insurance_detail" || (key === "primary_insurance_detail" && data?.referral_info.self_pay) ? true : data.referral_info[key];
          if (nestedObject && typeof nestedObject === 'object') {
            // Check if all keys inside the nested object have values
            for (const nestedKey in nestedObject) {
              if (!nestedObject[nestedKey]) {
                isDisabled = true;
                break; // Exit the loop if any key has no value
              }
            }
          } else {
            // If the value is not an object, check if it has a value
            if (!nestedObject) {
              isDisabled = true;
              break; // Exit the loop if any key has no value
            }
          }

          if (isDisabled) {
            break; // Exit the loop if any nested object key has no value
          }
        }
      }
    } else {
      isDisabled = false
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }


    for (let i = 0; i < data?.review_of_symptoms1.length; i++) {
      const element = data?.review_of_symptoms1[i];
      if (!element.selected) {
        isDisabled = true;
        break;
      } else {
        isDisabled = false;
      }
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }
    for (let i = 0; i < data?.review_of_symptoms2.length; i++) {
      const element = data?.review_of_symptoms2[i];
      if (!element.selected) {
        isDisabled = true;
        break;
      } else {
        isDisabled = false;
      }
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }


    if (data?.social_history?.smoker_type === "former_smoker" && hasNoEmptyValues(data?.social_history.former_smoker) && data?.social_history?.current_smoker.do_you_drink && (data?.social_history?.current_smoker?.how_often_do_you_drink)) {
      isDisabled = false;
    } else if (data?.social_history?.smoker_type === "current_smoker" && hasNoEmptyValues(data?.social_history.current_smoker)) {
      isDisabled = false;
    } else if (data?.social_history?.smoker_type === "non_smoker" && data?.social_history?.current_smoker.do_you_drink && (data?.social_history?.current_smoker?.how_often_do_you_drink || data?.social_history?.current_smoker.do_you_drink === "no")) {
      isDisabled = false;
    } else if (!data?.social_history?.smoker_type) {
      isDisabled = true;
    } else {
      isDisabled = true;
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (data?.start?.reason_for_visit && data?.start?.date && data?.start?.relationship_to_patient && data?.start?.legal_guradian) {
      isDisabled = false
    } else {
      isDisabled = true
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (data?.surgery_history?.none !== true) {

      let parentCondition = (data?.surgery_history?.explain === "" || data?.surgery_history?.have_anesthesia === "" || data?.surgery_history?.procedure[0].procedure === "" || data?.surgery_history?.procedure[0].time === "");
      if (parentCondition) {
        isDisabled = true
      } else {
        isDisabled = false
      }

    } else {
      isDisabled = false
    }



    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    for (let i = 0; i < data?.the_epworth_sleepiness_scale?.length; i++) {
      const element = data?.the_epworth_sleepiness_scale[i];
      if (element.chanceOfDozing === "") {
        isDisabled = true;
        break;
      } else {
        isDisabled = false;
      }


    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (data?.patient_auth?.patient_name && data?.patient_auth?.auth_date && data?.patient_auth?.auth_signature?.signature && data?.patient_auth?.auth_current_date && data?.patient_auth?.auth_relation) {
      isDisabled = false
    } else {
      isDisabled = true
    }


    if (isDisabled === true) {
      setDisabled(true)
      return;
    }


    if (data?.patient_financial_policy?.patient_financial_signature?.signature && data?.patient_financial_policy?.patient_financial_name && data?.patient_financial_policy?.patient_financial_date) {
      isDisabled = false
    } else {
      isDisabled = true
    }



    if (isDisabled === true) {
      setDisabled(true)
      return;
    }


    if (data?.medication_history_consent?.patient_financial_signature?.signature && data?.medication_history_consent?.patient_consent_name && data?.medication_history_consent?.medication_consent_date) {
      isDisabled = false
    } else {
      isDisabled = true
    }

    if (isDisabled === true) {
      setDisabled(true)
      return;
    }
    if (data?.communication_consent?.communication_consent_signature?.signature && data?.communication_consent?.communication_patient_name && data?.communication_consent?.communication_consent_date) {
      isDisabled = false
    } else {
      isDisabled = true
    }

    if (isDisabled === true) {
      setDisabled(true)
      return;
    }
    if (data?.procedures_in_office?.office_procedures_signature?.signature && data?.procedures_in_office?.procedure_print_name && data?.procedures_in_office?.procedure_relation && data?.procedures_in_office?.procedure_date) {
      isDisabled = false
    } else {
      isDisabled = true
    }
    if (isDisabled === true) {
      setDisabled(true)
      return;
    }

    if (data?.patient_info?.sex === "Female") {
      if (!data?.recreational_drug_use?.female_only) {
        isDisabled = true;
      } else {
        isDisabled = false;
      }
    } else {
      isDisabled = false;
    }
    if (isDisabled === true) {
      setDisabled(true)
      return;
    } else {
      setDisabled(isDisabled)
      setCheckValues(false);
    }
  }, [data])

  useEffect(() => {

    if (disabled && formSubmitted) {
      setCheckValues(true);
    }

  }, [disabled])


  const hasNoEmptyValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        return false;
      }
    }
    return true;
  }



  const handleAllData = () => {

    !formSubmitted && setFormSubmitted(true);
    if (!disabled) {
      setCheckValues(false);
      sendAllData();
    } else {
      setCheckValues(true);
      handleButtonClick();
    }
  }

  return (
    <>
      <FormUrlComponent data={data} setData={setData} checkValues={checkValues} />
      <div style={{
        display: "flex", justifyContent: "end",
      }}>
        <button
          disabled={checkValues}
          onClick={handleAllData}
          style={{
            background: checkValues ? "gray" : "rgb(93, 50, 141)",
            color: "white",
            width: "140px",
            height: "40px",
            marginTop: "25px",
            marginBottom: "5px",
            border: checkValues ? "1px solid gray" : "1px solid rgb(93, 50, 141)",
            cursor: "pointer",
            marginRight: "2%",

          }}
        >
          {isLoading ? <Spin size="small" /> : "Submit"}
        </button>
      </div>
      {showAlert && (
        <div
          style={{
            position: 'fixed',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
            width: '300px',
          }}
        >
          <Alert
            message="Data Error"
            description="Please fill All Required Fields."
            type="error"
            showIcon
            closable
            onClose={() => setShowAlert(false)}
          />
        </div>
      )}
    </>
  );
}

export default App;
