import { DatePicker } from "antd";
import { useEffect } from "react";

const FormSection1 = ({ data, setData, checkValues }) => {
	const handleChange = (e) => {
		const { name, value } = e.target;
		setData((prevState) => ({
			...prevState,
			start: { ...prevState.start, [name]: value },
		}));
	};
	return (
		<>
			<div className="fs1MainContainer">
				<div className="fs1Section1">
					<div className="fs1ItemVisit">
						<div className="fs1SectionHead endingItem">
							<h3>REASON FOR TODAY’S VISIT:</h3>
							<p className="italics">MOTIVO DE LA VISITA DE HOY</p>
						</div>
						<input
							type="text"
							value={data.start.reason_for_visit}
							name="reason_for_visit"
							onChange={(e) => handleChange(e)}
							className={`${checkValues && !data.start.reason_for_visit ? "missingInputField" : ""}`}
						/>
					</div>
					<div className="fs1ItemDate">
						<div className="fs1SectionHead endingItem">
							<h3>DATE:</h3>
							<p className="italics">FECHA</p>
						</div>
						<DatePicker
							name="date"
							onChange={(date, dateString) => handleChange({ target: { name: "date", value: dateString } })}
							className={`${checkValues && !data.start.date ? "missingInputField" : ""} marginTop2 width70 customMargin fs2dateStyle`}
						/>
					</div>
				</div>

				<div className="fs1Section2">
					<div className="fs1ItemRel">
						<div className="fs1SectionHead endingItem">
							<h3>RELATIONSHIP TO PATIENT:</h3>
							<p className="italics">RELACIÓN CON EL PACIENTE</p>
						</div>
						<input
							type="text"
							value={data.start.relationship_to_patient}
							name="relationship_to_patient"
							onChange={(e) => handleChange(e)}
							className={`${checkValues && !data.start.relationship_to_patient ? "missingInputField" : ""}`}
						/>
					</div>
					<div className="fs1ItemLeg">
						<div className="fs1SectionHead endingItem">
							<h3>LEGAL GUARDIAN:</h3>
							<p className="italics">GUARDIÁN LEGAL</p>
						</div>
						<input
							type="text"
							value={data.start.legal_guradian}
							name="legal_guradian"
							onChange={(e) => handleChange(e)}
							className={`${checkValues && !data.start.legal_guradian ? "missingInputField" : ""}`}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default FormSection1;
