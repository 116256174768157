import { DatePicker } from "antd";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { useState } from "react";
import { useEffect } from "react";
import { disabledDate } from "../../../utils/functions";

const raceArray = [
	{ value: 'White', label: 'White', translation: 'Blanco' },
	{ value: 'Hispanic', label: 'Hispanic', translation: 'Hispánico' },
	{ value: 'Asian', label: 'Asian', translation: 'Asiático' },
	{
		value: 'African American',
		label: 'African American',
		translation: 'Afro Americano',
	},
	{
		value: 'American Indian / Alaskan Native',
		label: 'American Indian / Alaskan Native',
		translation: 'ndio Americano / Nativo de Alaska',
	},
	{
		value: 'Native Hawaiian or Other Pacific Islander',
		label: 'Native Hawaiian or Other Pacific Islander',
		translation: 'Nativo de Hawai u otra isla del Pacifico',
	},
	{ value: 'Other Race', label: 'Other Race', translation: 'Otra raza' },
	{
		value: 'Unreported / Refused to Report',
		label: 'Unreported / Refused to Report',
		translation: 'No informado / rechazado a informar',
	},
];

const ethnicityArray = [
	{
		label: 'Hispanic or Latino',
		value: 'Hispanic or Latino',
		translation: 'Hispánico o Latino',
	},
	{
		label: 'Not Hispanic or Latino',
		value: 'Not Hispanic or Latino',
		translation: 'No Hispánico o Latino',
	},
	{
		label: 'Refused to Report',
		value: 'Refused to Report',
		translation: 'Rechazado a informar',
	},
];

const languageArray = [
	{ value: 'English', label: 'English', translation: 'Inglés ' },
	{ value: 'Spanish', label: 'Spanish', translation: 'Español' },
	{ value: 'Other', label: 'Other', translation: 'Otro' },
];

const FormSection4 = ({ data, setData, checkValues }) => {
	const [enableHippaApproved, setEnableHippaApproved] = useState(true);
	const handleFederalPrivacyChange = (event) => {
		const { name, value } = event.target;
		setData((prevState) => ({
			...prevState,
			federal_privacy_standards: {
				...prevState.federal_privacy_standards,
				[name]: value,
			},
		}));
	};

	const handleHippaApprovedContactsChange = (event, field1, field2) => {

		const { name, value, type, checked } = event.target;
		const newValue = type === "checkbox" ? checked : value;
		if (field2 === "none") {
			setEnableHippaApproved(!enableHippaApproved)
			setData((prevState) => ({
				...prevState,
				[field1]: {
					...prevState[field1],
					[field2]: newValue
					,
				},
			}));
		} else {

			setData((prevState) => ({
				...prevState,
				[field1]: {
					...prevState[field1],
					[field2]: {
						...prevState[field1][field2],
						[name]: newValue,
					},
				},
			}));
		}

	};


	return (
		<>
			<div className="fs4MainContainer">
				<h2 className="fs4PrivacyHeading">
					Federal Privacy Standards require the following information
				</h2>
				<p className="fs4TranslatorPrivacyHeading">
					Los Estandares Federales de Privacidad requieren la siguiente
					información
				</p>

				<div>
					<div className="fs4Conatiner">
						<div className="fs4CheckboxConatiner flex ">
							<div className="fs4SubHeading" style={{ width: '15%' }}>
								<h3 style={{ fontSize: '1rem' }}>Race</h3>
								<p className="italics">Raza</p>
							</div>
							<div className={`${checkValues && !data?.federal_privacy_standards?.race ? "missingField" : ""} flex flex-wrap ml-2 `} style={{ width: '85%' }}>
								{raceArray.map((item) => (
									<div key={item.value}>
										<div className="radio-main">
											<input
												type="radio"
												name="race"
												className="radio-custom"
												id={item.value}
												value={item.value}
												onChange={handleFederalPrivacyChange}
											/>
											<label htmlFor={item.value}>{item.label}</label>
										</div>
										<p className="italics checkboxTranslate">
											{item.translation}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="fs4Conatiner">
						<div className="fs4CheckboxConatiner flex ">
							<div className="fs4SubHeading" style={{ width: '15%' }}>
								<h3 style={{ fontSize: '1rem' }}>Ethnicity</h3>
								<p className="italics">Etnicidad</p>
							</div>
							<div className={` ${checkValues && !data?.federal_privacy_standards?.ethnicity ? "missingField" : ""} flex flex-wrap ml-2`} style={{ width: '85%' }}>
								{ethnicityArray.map((item) => (
									<div key={item.value}>
										<div className="radio-main">
											<input
												type="radio"
												name="ethnicity"
												className={` radio-custom`}
												id={item.value}
												value={item.value}
												onChange={handleFederalPrivacyChange}
											/>
											<label htmlFor={item.value}>{item.label}</label>
										</div>
										<p className="italics checkboxTranslate">
											{item.translation}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
					<div className="fs4Conatiner">
						<div className="fs4CheckboxConatiner flex ">
							<div className="fs4SubHeading" style={{ width: '15%' }}>
								<h3 style={{ fontSize: '1rem' }}>Preferred Language:</h3>
								<p className="italics">Idioma preferido</p>
							</div>

							<div className={` ${checkValues && !data?.federal_privacy_standards?.preffered_language ? "missingField" : ""} flex flex-wrap ml-2`} style={{ width: '85%' }}>
								{languageArray.map((item) => (
									<div key={item.value}>
										<div className="radio-main">
											<input
												type="radio"
												name="preffered_language"
												className="radio-custom"
												id={item.value + 'preffered_language'}
												value={item.value}
												onChange={handleFederalPrivacyChange}
											/>
											<label htmlFor={item.value + 'preffered_language'}>
												{item.label}
											</label>
										</div>
										<p className="italics checkboxTranslate">
											{item.translation}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className={`secondary_container ${!enableHippaApproved ? "disabledSection" : ""}`}>
					<div className="flex align-items-center">
						<div>
							<h1 className="fs4Heading">HIPAA APPROVED CONTACTS:</h1>
							<p className="fs4HeadingTranslator">CONTACTOS APROBADOS POR HIPAAA</p>
						</div>
						<div className="flex">
							<div className="familyHistorySectionCheckbox activeChild">
								<input
									type="checkbox"
									name="none"
									id="none_family_history"
									// checked={data.family_history.none}
									onChange={(e) =>
										handleHippaApprovedContactsChange(
											e,
											'hippa_approved_contacts',
											'none'
										)
									}
								/>
								<label htmlFor="none_family_history">None</label>
								<p className="italics">Ninguno</p>
							</div>
						</div>
					</div>
					<ul>
						<li className="fsListItem">
							Please list the individuals you give permission to have access to
							and discuss your protected health information.
							<p className="fs4TranslatorPrivacyHeading">
								Enumere las personas a las que les da permiso para tener acceso
								y discuta su información de salud protegida.
							</p>
						</li>
						<li className="fsListItem">
							Write ‘NONE’ if there are no authorized individuals.
							<p className="fs4TranslatorPrivacyHeading">
								Escriba ‘NINGUNO’ si no hay personas autorizadas
							</p>
						</li>
					</ul>

					<div className="fs4Container">
						<div className="fs3CustomStyling" style={{ width: '30%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '60px' }}>
								<h3>Name:</h3>
								<p className="italics">Nombre</p>
							</div>
							<input
								className={` ${checkValues && !data?.hippa_approved_contacts?.none && !data?.hippa_approved_contacts.contact1.name ? "missingField" : ""} `}
								type="text"
								name="name"
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'hippa_approved_contacts',
										'contact1'
									)
								}
								value={data.hippa_approved_contacts.contact1.name}

							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '20%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '155px' }}>
								<h3>DOB:</h3>
								<p className="italics">Fecha de nacimiento</p>
							</div>
							<DatePicker
								name="DOB"
								onChange={(date, dateString) =>
									handleHippaApprovedContactsChange(
										{ target: { value: dateString, name: "DOB" } },
										'hippa_approved_contacts',
										'contact1'
									)
								}
								disabledDate={disabledDate}
								className={` ${checkValues && !data?.hippa_approved_contacts?.none && !data?.hippa_approved_contacts.contact1.DOB ? "missingInputField" : ""} w-100 marginTop2 fs2dateStyle `}

							/>

						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '20%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '150px' }}>
								<h3>Phone:</h3>
								<p className="italics">Numero de Telefono</p>
							</div>
							<input
								type="number"
								name="phone"
								min={0}
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'hippa_approved_contacts',
										'contact1'
									)
								}
								className={` ${checkValues && !data?.hippa_approved_contacts?.none && !data?.hippa_approved_contacts.contact1.phone ? "missingInputField" : ""} `}

								value={data.hippa_approved_contacts.contact1.phone}
							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '25%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '300px' }}>
								<h3>Relation to patient:</h3>
								<p className="italics">Relación</p>
							</div>
							<input
								type="text"
								name="relationship_to_patient"
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'hippa_approved_contacts',
										'contact1'
									)
								}

								value={
									data.hippa_approved_contacts.contact1.relationship_to_patient
								}
								className={` ${checkValues && !data?.hippa_approved_contacts?.none && !data?.hippa_approved_contacts.contact1.relationship_to_patient ? "missingField" : ""} `}
							/>
						</div>
					</div>

					<div className="fs4Container">
						<div className="fs3CustomStyling" style={{ width: '30%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '60px' }}>
								<h3>Name:</h3>
								<p className="italics">Nombre</p>
							</div>
							<input
								type="text"
								name="name"
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'hippa_approved_contacts',
										'contact2'
									)
								}
								value={data.hippa_approved_contacts.contact2.name}

							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '20%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '155px' }}>
								<h3>DOB:</h3>
								<p className="italics">Fecha de nacimiento</p>
							</div>
							<DatePicker
								name="DOB"
								onChange={(date, dateString) =>
									handleHippaApprovedContactsChange(
										{ target: { value: dateString, name: "DOB" } },
										'hippa_approved_contacts',
										'contact2'
									)
								}
								disabledDate={disabledDate}

								// value={dayjs(data.hippa_approved_contacts.contact1.DOB || new Date().toLocaleString('en-CA'), 'YYYY-MM-DD')}
								className="w-100 marginTop2 fs2dateStyle"
							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '20%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '150px' }}>
								<h3>Phone:</h3>
								<p className="italics">Numero de Telefono</p>
							</div>
							<input
								type="number"
								name="phone"
								min={0}
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'hippa_approved_contacts',
										'contact2'
									)
								}
								className="className={` ${checkValues"
								value={data.hippa_approved_contacts.contact2.phone}
							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '25%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '300px' }}>
								<h3>Relation to patient:</h3>
								<p className="italics">Relación</p>
							</div>
							<input
								type="text"
								name="relationship_to_patient"
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'hippa_approved_contacts',
										'contact2'
									)
								}
								className="className={` ${checkValues"
								value={
									data.hippa_approved_contacts.contact2.relationship_to_patient
								}
							/>
						</div>
					</div>
				</div>

				<div>
					<h1 className="fs4Heading">EMERGENCY CONTACT (REQUIRED):</h1>
					<p className="fs4HeadingTranslator">
						CCONTACTO DE EMERGENCIA (REQUERIDO)
					</p>

					<div className="fs4Container">
						<div className="fs3CustomStyling" style={{ width: '30%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '60px' }}>
								<h3>Name:</h3>
								<p className="italics">Nombre</p>
							</div>
							<input
								type="text"
								name="name"
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'emergency_contact',
										'contact1'
									)
								}
								value={data.emergency_contact.contact1.name}
								className={` ${checkValues && !data?.emergency_contact?.contact1?.name ? "missingInputField" : ""}`}
							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '20%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '155px' }}>
								<h3>DOB:</h3>
								<p className="italics">Fecha de nacimiento</p>
							</div>
							<DatePicker
								onChange={(date, dateString) =>
									handleHippaApprovedContactsChange(
										{ target: { value: dateString, name: 'DOB' } },
										'emergency_contact',
										'contact1'
									)
								}
								name="DOB"
								className={`${checkValues && !data?.emergency_contact?.contact1?.DOB ? "missingInputField" : ""} w-100 marginTop2 fs2dateStyle`}

								disabledDate={disabledDate}
							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '20%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '150px' }}>
								<h3>Phone:</h3>
								<p className="italics">Numero de Telefono</p>
							</div>
							<input
								type="number"
								name="phone"
								min={0}
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'emergency_contact',
										'contact1'
									)
								}
								className={` ${checkValues && !data?.emergency_contact?.contact1?.phone ? "missingInputField" : ""}`}
								value={data.emergency_contact.contact1.phone}
							/>
						</div>{' '}
						<div className="fs3CustomStyling" style={{ width: '25%' }}>
							<div className="fs3labels fs4labels endingItem" style={{ width: '300px' }}>
								<h3>Relation to patient:</h3>
								<p className="italics">Relación</p>
							</div>
							<input
								type="text"
								name="relationship_to_patient"
								onChange={(e) =>
									handleHippaApprovedContactsChange(
										e,
										'emergency_contact',
										'contact1'
									)
								}
								className={` ${checkValues && !data?.emergency_contact?.contact1?.relationship_to_patient ? "missingInputField" : ""}`}
								value={data.emergency_contact.contact1.relationship_to_patient}
							/>
						</div>
					</div>
				</div>

				<div className="fs2Border" />
			</div>
		</>
	);
};

export default FormSection4;
