export const calculateAge = (birthdate) => {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();

    const ageInMilliseconds = currentDate - birthDate;
    const ageInSeconds = ageInMilliseconds / 1000;
    const ageInMinutes = ageInSeconds / 60;
    const ageInHours = ageInMinutes / 60;
    const ageInDays = ageInHours / 24;
    const ageInYears = ageInDays / 365.25; // accounting for leap years

    const roundedAge = Math.round(ageInYears);

    return roundedAge;
}

// Function to disable dates greater than today
export const disabledDate = (current) => {
    // Get the current date without time
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Disable dates greater than tomorrow
    return current && current > new Date(today.getTime() + 86400000);
};
