import { useEffect, useState } from "react";
import FormSection6 from "./FormSection6";
import FormSection7 from "./FormSection7";

const multiChoic = [
  {
    condition: "Acid reflux (GERD)",
    translation: "Reflujo ácido (ERGE)",
    name: "acid",
    id: 1,
    selected: null,
  },
  {
    condition: "Anemia",
    translation: "Anemia",
    name: "anemia",
    id: 2,
    selected: null,
  },
  {
    condition: "Asthma",
    translation: "Asma",
    name: "asma",
    id: 3,
    selected: null,
  },
  {
    condition: "Atrial fibrillation",
    translation: "Fibrilación auricular",
    name: "atrial",
    id: 4,
    selected: null,
  },
  {
    condition: "Bronchitis, chronic",
    translation: "Bronquitis crónica",
    name: "chronic",
    id: 5,
    selected: null,
  },
  {
    condition: "Cancer, breast",
    translation: "Cáncer de mama",
    name: "breast",
    id: 6,
    selected: null,
  },
  {
    condition: "Cancer, lung",
    translation: "Cáncer de pulmón",
    name: "lung",
    id: 7,
    selected: null,
  },
  {
    condition: "Cancer, prostate",
    translation: "Cáncer de próstata",
    name: "prostate",
    id: 8,
    selected: null,
  },
  {
    condition: "Cancer, skin",
    translation: "Cáncer de piel",
    name: "skin",
    id: 9,
    selected: null,
  },
  {
    condition: "Cancer, thyroid",
    translation: "Cáncer de tiroides",
    name: "thyroid",
    id: 10,
    selected: null,
  },
  {
    condition: "Cataracts",
    translation: "Cataratas",
    name: "cataracts",
    id: 11,
    selected: null,
  },
  {
    condition: "Congestive heart failure",
    translation: "Insuficiencia cardíaca congestiva",
    name: "heartFailure",
    id: 12,
    selected: null,
  },
  {
    condition: "Deep vein thrombosis (DVT)",
    translation: "Trombosis venosa profunda",
    name: "dvt",
    id: 13,
    selected: null,
  },
  {
    condition: "Dementia",
    translation: "Demencia",
    name: "dementia",
    id: 14,
    selected: null,
  },
  {
    condition: "Depression",
    translation: "Depresión",
    name: "depression",
    id: 15,
    selected: null,
  },
  {
    condition: "Diabetes mellitus",
    translation: "Diabetes mellitus",
    name: "diabetes",
    id: 16,
    selected: null,
  },
  {
    condition: "Elevated cholesterol",
    translation: "Colesterol elevado",
    name: "elevated",
    id: 17,
    selected: null,
  },
  {
    condition: "Emphysema (COPD)",
    translation: "Enfisema",
    name: "copd",
    id: 18,
    selected: null,
  },
  {
    condition: "Environmental allergies",
    translation: "Alergias ambientales",
    name: "allergies",
    id: 19,
    selected: null,
  },
  {
    condition: "Epilepsy",
    translation: "Epilepsia",
    name: "epilepsy",
    id: 20,
    selected: null,
  },
  {
    condition: "Gastric ulcer",
    translation: "Úlcera gástrica",
    name: "gastric",
    id: 21,
    selected: null,
  },
  {
    condition: "Glaucoma",
    translation: "Glaucoma",
    name: "glaucoma",
    id: 22,
    selected: null,
  },
  {
    condition: "Heart attack or cardiac stents",
    translation: "Ataque al corazón",
    name: "heartAttack",
    id: 23,
    selected: null,
  },
  {
    condition: "Hemophilia",
    translation: "Hemofilia",
    name: "hemophilia",
    id: 24,
    selected: null,
  },
  {
    condition: "Hepatitis B",
    translation: "Hepatitis B",
    name: "hepatitisB",
    id: 25,
    selected: null,
  },
  {
    condition: "Hepatitis C",
    translation: "Hepatitis C",
    name: "hepatitisC",
    id: 26,
    selected: null,
  },
  {
    condition: "HIV / AIDS",
    translation: "VIH / SIDA",
    name: "HIV",
    id: 27,
    selected: null,
  },
  {
    condition: "Hypertension",
    translation: "Hipertensión",
    name: "hypertension",
    id: 28,
    selected: null,
  },
  {
    condition: "Migraine headaches",
    translation: "Migrañas",
    name: "headaches",
    id: 29,
    selected: null,
  },
  {
    condition: "Multiple sclerosis",
    translation: "Esclerosis múltiple",
    name: "sclerosis",
    id: 30,
    selected: null,
  },
  {
    condition: "Parkinson’s disease",
    translation: "Enfermedad de Parkinson",
    name: "pDisease",
    selected: null,
  },
  {
    condition: "Renal failure",
    translation: "Insuficiencia renal",
    name: "renal",
    id: 31,
    selected: null,
  },
  {
    condition: "Pulmonary embolism (PE)",
    translation: "Embolia Pulmonar",
    name: "pe",
    id: 32,
    selected: null,
  },
  {
    condition: "Sleep apnea",
    translation: "Apnea del sueño",
    name: "sleepApnea",
    id: 33,
    selected: null,
  },
  {
    condition: "Rheumatoid arthritis",
    translation: "Artritis Reumatoide",
    name: "rheumatoid",
    id: 34,
    selected: null,
  },
  {
    condition: "Stroke",
    translation: "Accidente cerebrovascular medico",
    name: "stroke",
    id: 35,
    selected: null,
  },
  {
    condition: "Others",
    translation: "Otro",
    name: "otherDiease",
    id: 36,
    selected: null,
  },
];

const reviewSymptomsArray1 = [
  {
    id: 1,
    condition: "Fatigue",
    translation: "Fatiga",
    name: "fatigue",
    selected: null,
  },
  {
    id: 2,
    condition: "Fever",
    translation: "Fiebre",
    name: "fever",
    selected: null,
  },
  {
    id: 3,
    condition: "Headache",
    translation: "Dolor de cabeza",
    name: "headache",
    selected: null,
  },
  {
    id: 4,
    condition: "Sleep disturbance",
    translation: "Trastornos del sueño",
    name: "sleepdisturbance",
    selected: null,
  },
  {
    id: 5,
    condition: "Weight gain",
    translation: "Aumento de peso",
    name: "weightgain",
    selected: null,
  },
  {
    id: 6,
    condition: "Weight loss",
    translation: "Pérdida de peso",
    name: "weightloss",
    selected: null,
  },
  {
    id: 7,
    condition: "Congestion",
    translation: "Congestión",
    name: "congestion",
    selected: null,
  },
  {
    id: 8,
    condition: "Sneezing",
    translation: "Estornudos",
    name: "sneezing",
    selected: null,
  },
  {
    id: 9,
    condition: "Runny nose",
    translation: "Secreción nasal",
    name: "runnynose",
    selected: null,
  },
  {
    id: 10,
    condition: "Watery eyes",
    translation: "Ojos llorosos",
    name: "wateryeyes",
    selected: null,
  },
  {
    id: 11,
    condition: "Blurred vision",
    translation: "Visión borrosa",
    name: "blurredvision",
    selected: null,
  },
  {
    id: 12,
    condition: "Diminished visual acuity",
    translation: "Disminución de la agudeza visual",
    name: "diminishedvisualacuity",
    selected: null,
  },
  {
    id: 13,
    condition: "Itching and redness",
    translation: "Picazón y enrojecimiento",
    name: "itchingandredness",
    selected: null,
  },
  {
    id: 14,
    condition: "Decreased hearing",
    translation: "Disminución de la audición",
    name: "decreasedhearing",
    selected: null,
  },
  {
    id: 15,
    condition: "Decreased sense of smell",
    translation: "Disminución del sentido del olfato",
    name: "decreasedsenseofsmell",
    selected: null,
  },
];

const reviewSymptomsArray2 = [
  {
    id: 16,
    condition: "Difficulty swallowing",
    translation: "Dificultad al tragar",
    name: "difficultyswallowing",
    selected: null,
  },
  {
    id: 17,
    condition: "Dry mouth",
    translation: "Boca seca",
    name: "drymouth",
    selected: null,
  },
  {
    id: 18,
    condition: "Ear pain",
    translation: "Dolor de oído",
    name: "earpain",
    selected: null,
  },
  {
    id: 19,
    condition: "Nose bleed",
    translation: "Sangrado de nariz",
    name: "nosebleed",
    selected: null,
  },
  {
    id: 20,
    condition: "Ringing in ears",
    translation: "Zumbido en los oídos",
    name: "ringinginears",
    selected: null,
  },
  {
    id: 21,
    condition: "Sinus pain",
    translation: "Dolor de senos nasales",
    name: "sinuspain",
    selected: null,
  },
  {
    id: 22,
    condition: "Sore throat",
    translation: "Dolor de garganta",
    name: "sorethroat",
    selected: null,
  },
  {
    id: 23,
    condition: "Swollen glands",
    translation: "Glándulas inflamadas",
    name: "swollenglands",
    selected: null,
  },
  {
    id: 24,
    condition: "Cough",
    translation: "Tos",
    name: "cough",
    selected: null,
  },
  {
    id: 25,
    condition: "Shortness of breath at rest",
    translation: "Dificultad para respirar en reposo",
    name: "shortnessofbreathatrest",
    selected: null,
  },
  {
    id: 26,
    condition: "Wheezing",
    translation: "Sibilancias",
    name: "wheezing",
    selected: null,
  },
  {
    id: 27,
    condition: "Irregular heartbeat",
    translation: "Latidos irregulares",
    name: "irregularheartbeat",
    selected: null,
  },
  {
    id: 28,
    condition: "Diarrhea",
    translation: "Diarrea",
    name: "diarrhea",
    selected: null,
  },
  {
    id: 29,
    condition: "Heartburn",
    translation: "Acidez estomacal",
    name: "heartburn",
    selected: null,
  },
  {
    id: 30,
    condition: "Nausea",
    translation: "Náuseas",
    name: "nausea",
    selected: null,
  },
  {
    id: 31,
    condition: "Vomiting",
    translation: "Vómitos",
    name: "vomiting",
    selected: null,
  },
  {
    id: 32,
    condition: "Easy bruising",
    translation: "Fácil aparición de hematomas",
    name: "easybruising",
    selected: null,
  },
  {
    id: 33,
    condition: "Prolonged bleeding",
    translation: "Sangrado prolongado",
    name: "prolongedbleeding",
    selected: null,
  },
  {
    id: 34,
    condition: "Joint stiffness",
    translation: "Rigidez en las articulaciones",
    name: "jointstiffness",
    selected: null,
  },
  {
    id: 35,
    condition: "Leg cramps",
    translation: "Calambres en las piernas",
    name: "legcramps",
    selected: null,
  },
  {
    id: 36,
    condition: "Muscle aches",
    translation: "Dolores musculares",
    name: "muscleaches",
    selected: null,
  },
  {
    id: 37,
    condition: "Eczema",
    translation: "Eczema",
    name: "eczema",
    selected: null,
  },
  {
    id: 38,
    condition: "Hives",
    translation: "Urticaria",
    name: "hives",
    selected: null,
  },
  {
    id: 39,
    condition: "Rash",
    translation: "Erupción",
    name: "rash",
    selected: null,
  },
  {
    id: 40,
    condition: "Dizziness",
    translation: "Mareos",
    name: "dizziness",
    selected: null,
  },
  {
    id: 41,
    condition: "Seizures",
    translation: "Convulsiones",
    name: "seizures",
    selected: null,
  },
  {
    id: 42,
    condition: "Tremors",
    translation: "Temblores",
    name: "tremors",
    selected: null,
  },
];
const FormSection5 = ({ setData, data, checkValues }) => {
  const [medicalHistory, setMedicalHistory] = useState(multiChoic);
  const [reviewSymptoms1, setReviewSymptoms1] = useState(reviewSymptomsArray1);
  const [reviewSymptoms2, setReviewSymptoms2] = useState(reviewSymptomsArray2);

  const handleSelectionChange = (id, value) => {
    const updatedMultiChoice = medicalHistory.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          selected: value,
        };
      }
      return item;
    });

    setMedicalHistory(updatedMultiChoice);
  };

  function filterArray(array) {
    return array.map(({ condition, selected }) => ({ condition, selected }));
  }

  useEffect(() => {
    // const filteredArray = filterArray(medicalHistory);

    setData((prevState) => ({
      ...prevState,
      medical_history: [...medicalHistory],
    }));


  }, [medicalHistory, setData]);

  return (
    <div className="fs5Main flex">
      <div className="fs5Section1">
        <div>
          <div className="medicHistoryText">
            <h3 className="fs5Heading">MEDICAL HISTORY:</h3>
            <p className="fs5BoldText ">ALL PATIENTS</p>
          </div>
          <p className="fs5TranslationText">
            HISTORIAL MÉDICO: TODOS LOS PACIENTES
          </p>
          <p className="fs5SubHeading">
            Have you ever been <span className="fs5BoldText">DIAGNOSED</span>{" "}
            with any of the following conditions?
          </p>
          <p className="fs5TranslationText">
            ¿Alguna vez ha sido DIAGNOSTICO con alguna de las siguientes
            condiciones?
          </p>
        </div>
        {medicalHistory.map((item, index) => {
          return (
            <div key={item.id}>
              <div className="fs5RadioContainer">
                <h3 className="fs5RadioHeading">
                  {item.condition}
                  <span className="fs5RadioHeadingTranslation">
                    {item.translation}
                  </span>
                </h3>
                <div className={`fs5CheckboxContainer `}>
                  <div className={`flex flex-wrap ml-2 ${checkValues && !data?.medical_history[index].selected ? "missingField" : ""} `} style={{ width: "95%" }}>
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name={"medhistory" + item.id}
                          className="radio-custom"
                          id={"fs5yes" + item.id}
                          value={"Yes"}
                          onChange={() => handleSelectionChange(item.id, "Yes")}
                          checked={item.selected === "Yes"}
                        />
                        <label htmlFor={"fs5yes" + item.id}>
                          Yes <span className="italics">Si</span>
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name={"medhistory" + item.id}
                          className="radio-custom"
                          id={"fs5no" + item.id}
                          value={"No"}
                          onChange={() => handleSelectionChange(item.id, "No")}
                          checked={item.selected === "No"}
                        />
                        <label htmlFor={"fs5no" + item.id}>
                          No <span className="italics">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="fs5Border" />
        <FormSection6
          data={data}
          setData={setData}
          checkValues={checkValues}

          reviewSymptoms={reviewSymptoms1}
          setReviewSymptoms={setReviewSymptoms1}
        />
      </div>
      <div className="fs5Section2">
        <FormSection7
          data={data}
          setData={setData}
          checkValues={checkValues}

          reviewSymptoms={reviewSymptoms2}
          setReviewSymptoms={setReviewSymptoms2}
        />
      </div>
    </div>
  );
};

export default FormSection5;
