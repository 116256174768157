import { Radio } from "antd";
import "./FormPage4.css";
import { useEffect, useState } from "react";
import { InternalBreadcrumbItem } from "antd/es/breadcrumb/BreadcrumbItem";

const sleepnessScaleArray = [
  {
    condition: "Sitting and reading",
    conditionTranslate: "Sentado y leyendo",
    chanceOfDozing: "",
    id: 1,
  },
  {
    condition: "Watching television",
    conditionTranslate: "Viendo televisión",
    chanceOfDozing: "",
    id: 2,
  },
  {
    condition: "Sitting inactive in public place, e.g. ,theater or meeting",
    conditionTranslate:
      "Sentado inactivo en un lugar publico, por ejemplo, teatro o reunión",
    chanceOfDozing: "",
    id: 3,
  },
  {
    condition: "As a passenger in a car for an hour without a break",
    conditionTranslate:
      "Como pasajero en un automóvil por una hora sin descanso",
    chanceOfDozing: "",
    id: 4,
  },
  {
    condition: "Lying down to rest in the afternoon",
    conditionTranslate: "Acostarse para descansar por la tarde",
    chanceOfDozing: "",
    id: 5,
  },
  {
    condition: "Sitting and talking to someone",
    conditionTranslate: "Sentado y hablando con alguien",
    chanceOfDozing: "",
    id: 6,
  },
  {
    condition: "Sitting quietly after lunch without alcohol",
    conditionTranslate: "Sentarse en silencio después del almuerzo sin alcohol",
    chanceOfDozing: "",
    id: 7,
  },
  {
    condition: "In a car, while stopped in a few minutes of traffic",
    conditionTranslate:
      "En un automóvil, mientras se detiene en unos minutos de tráfico",
    chanceOfDozing: "",
    id: 8,
  },
];

const hearingHistoryArray = [
  {
    condition: "Ringing or other sounds in ears",
    conditionTranslate: "Zumbidos u otros sonidos en los oídos",
    chanceOfDozing: "",
    id: 1,
  },
  {
    condition: "Chronic ear infections",
    conditionTranslate: "Infecciones crónicas del oído",
    chanceOfDozing: "",
    id: 2,
  },
  {
    condition: "Earwax build up",
    conditionTranslate: "Acumulación de cerumen",
    chanceOfDozing: "",
    id: 3,
  },
  {
    condition: "Fullness in ears",
    conditionTranslate: "Sensación tapada en los oídos",
    chanceOfDozing: "",
    id: 4,
  },
  {
    condition: "Pressure in ears",
    conditionTranslate: "Presión en los oídos",
    chanceOfDozing: "",
    id: 5,
  },
  {
    condition: "Perforated eardrum",
    conditionTranslate: "Tímpano perforado",
    chanceOfDozing: "",
    id: 6,
  },
  {
    condition: "Family history of hearing loss",
    conditionTranslate: "Antecedentes familiares de pérdida auditiva",
    chanceOfDozing: "",
    id: 7,
  },
  {
    condition: "Exposed to loud noises",
    conditionTranslate: "Expuesto a ruidos fuertes",
    chanceOfDozing: "",
    id: 8,
  },
  {
    condition: "Trauma to head",
    conditionTranslate: "Trauma a la cabeza",
    chanceOfDozing: "",
    id: 9,
  },
  {
    condition: "Dizziness or vertigo",
    conditionTranslate: "Mareos o vértigo",
    chanceOfDozing: "",
    id: 10,
  },
  {
    condition: "Sinus or allergy problems",
    conditionTranslate: "Problemas de sinusitis o alergia",
    chanceOfDozing: "",
    id: 11,
  },
  {
    condition: "Have you had a hearing test?",
    conditionTranslate: "¿Te has hecho una prueba de audición?",
    chanceOfDozing: "",
    id: 12,
  },
  {
    condition: "Have you had ear surgery?",
    conditionTranslate: "¿Te han operado el oído?",
    chanceOfDozing: "",
    id: 13,
  },
];

const allergyHistoryQuizQuestion = [
  {
    condition: "Nasal discharge",
    conditionTranslate: "Secreción nasal",
    chanceOfDozing: "",
    id: 1,
  },
  {
    condition: "Nasal obstruction",
    conditionTranslate: "Obstrucción nasal",
    chanceOfDozing: "",
    id: 2,
  },
  {
    condition: "Watery or itchy eyes",
    conditionTranslate: "Ojos llorosos o con picazón",
    chanceOfDozing: "",
    id: 3,
  },
  {
    condition: "Sneezing",
    conditionTranslate: "Estornudos",
    chanceOfDozing: "",
    id: 4,
  },
  {
    condition: "Wheezing",
    conditionTranslate: "Resollar",
    chanceOfDozing: "",
    id: 5,
  },
  {
    condition: "Cough",
    conditionTranslate: "Tos",
    chanceOfDozing: "",
    id: 6,
  },
  {
    condition: "Itching",
    conditionTranslate: "Comezón",
    chanceOfDozing: "",
    id: 7,
  },
  {
    condition: "Eczema",
    conditionTranslate: "Eczema",
    chanceOfDozing: "",
    id: 8,
  },
  {
    condition: "Hives",
    conditionTranslate: "Ronchas",
    chanceOfDozing: "",
    id: 9,
  },
  {
    condition: "Headache",
    conditionTranslate: "Dolor de cabeza",
    chanceOfDozing: "",
    id: 10,
  },
  {
    condition: "Chronic fatigue",
    conditionTranslate: "Fatiga cronica",
    chanceOfDozing: "",
    id: 11,
  },
  {
    condition: "Food intolerance",
    conditionTranslate: "Intolerancia a la comida",
    chanceOfDozing: "",
    id: 12,
  },
  {
    condition: "Frequent sinus or ear infections",
    conditionTranslate: "Frecuentes infecciones de los senositis o de los oído",
    chanceOfDozing: "",
    id: 13,
  },
  {
    condition: "Frequent colds or sore throats",
    conditionTranslate: "Resfriados frecuentes o dolor de garganta",
    chanceOfDozing: "",
    id: 14,
  },
  {
    condition: "Learning disability",
    conditionTranslate: "Discapacidad de aprendizaje",
    chanceOfDozing: "",
    id: 15,
  },
  {
    condition: "Poor memory or concentration",
    conditionTranslate: "Mala memoria o concentración",
    chanceOfDozing: "",
    id: 16,
  },
  {
    condition: "Hyperactivity",
    conditionTranslate: "Hiperactividad",
    chanceOfDozing: "",
    id: 17,
  },
  {
    condition: "Abdominal gas or cramping",
    conditionTranslate: "Gases abdominales o calambres",
    chanceOfDozing: "",
    id: 18,
  },
  {
    condition: "Arthritis or muscle aching",
    conditionTranslate: "Artritis o dolor muscular",
    chanceOfDozing: "",
    id: 19,
  },
  {
    condition: "Asthma",
    conditionTranslate: "Asma",
    chanceOfDozing: "",
    id: 20,
  },
];
const allergyHistoryPerscription = [
  {
    perscription: "",
    duration: "",
    id: 0,
  },
  {
    perscription: "",
    duration: "",
    id: 1,
  },
  {
    perscription: "",
    duration: "",
    id: 2,
  },
  {
    perscription: "",
    duration: "",
    id: 3,
  },
];
const allergyHistoryGeneralQuestions = [
  {
    name: "question1",
    question: "How long have you had allergy symptoms?",
    translation: "¿Cuánto tiempo ha tenido síntomas de alergia?",
    response: "",
    id: 1,
  },
  {
    name: "question2",
    question: "Year-round or seasonal?",
    translation: "¿Durante todo el año o estacional?",
    response: "",
    id: 2,
  },
  {
    name: "question3",
    question: "Have you been allergy tested before?",
    translation: "¿Se ha realizado pruebas de alergia antes?",
    response: "",
    id: 3,
  },
  {
    name: "question4",
    question: "If yes, did you receive immunotherapy?",
    translation: "Si es así, ¿recibió inmunoterapia?",
    response: "",
    id: 4,
  },
  {
    name: "question5",
    question: "Are you exposed to fumes, chemicals or dust at work?",
    translation:
      "¿Está expuesto a humos, productos químicos o polvo en el trabajo?",
    response: "",
    id: 5,
  },
];
const rangeArray = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];

const FormPage4 = ({ data, setData, checkValues }) => {
  const [sleepnessScale, setSleepnessScale] = useState(sleepnessScaleArray);
  const [hearingHistory, setHearingHistory] = useState(hearingHistoryArray);
  const [allergyHistory, setAllergyHistory] = useState(
    allergyHistoryQuizQuestion
  );
  const [allergyHistoryQuiz, setAllergyHistoryQuiz] = useState(
    allergyHistoryPerscription
  );
  const [allergyHistoryGeneral, setAllergyHistoryGeneral] = useState(
    allergyHistoryGeneralQuestions
  );

  const handleCheckboxChange = (e) => {
    setData({ ...data, allergy_history_questionnaire: { ...data.allergy_history_questionnaire, none: e.target.checked } })
  }
  const handleChangeInput = (event, field) => {
    const { name, value } = event.target;
    const updatedResponses = allergyHistoryGeneral.map((responseItem) => {
      if (responseItem.name === name) {
        return { ...responseItem, response: value };
      }
      return responseItem;
    });

    setAllergyHistoryGeneral(updatedResponses);
  };

  const handleSleepnessScaleChange = (id, value) => {
    const updatedMultiChoice = sleepnessScale.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          chanceOfDozing: value,
        };
      }
      return item;
    });

    setSleepnessScale(updatedMultiChoice);
  };

  const handleHearingHistoryChange = (id, value) => {
    const updatedMultiChoice = hearingHistory.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          chanceOfDozing: value,
        };
      }
      return item;
    });

    setHearingHistory(updatedMultiChoice);
  };

  const handleAllergyHistoryChange = (id, value) => {
    const updatedMultiChoice = allergyHistory.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          chanceOfDozing: value,
        };
      }
      return item;
    });

    setAllergyHistory(updatedMultiChoice);
  };

  const handleChangeAllergyQiuz = (index, event) => {
    const { name, value } = event.target;

    const updatedQuestions = [...allergyHistoryQuiz];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      [name]: value,
    };

    setAllergyHistoryQuiz(updatedQuestions);
  };

  function filterArray(array) {
    return array.map(({ condition, chanceOfDozing }) => ({
      condition,
      chanceOfDozing,
    }));
  }

  function filterArray2(array) {
    return array.map(({ question, response }) => ({
      question,
      response,
    }));
  }
  useEffect(() => {
    const filteredArray = filterArray(sleepnessScale);

    setData((prevState) => ({
      ...prevState,
      the_epworth_sleepiness_scale: [...filteredArray],
    }));


  }, [sleepnessScale, setData]);

  useEffect(() => {
    const filteredArray = filterArray(hearingHistory);

    setData((prevState) => ({
      ...prevState,
      hearing_history_questionnaire: [...filteredArray],
    }));

  }, [hearingHistory, setData]);

  useEffect(() => {
    // const filteredArray = filterArray(allergyHistory);

    setData((prevState) => ({
      ...prevState,
      allergy_history_questionnaire: {
        ...prevState.allergy_history_questionnaire,
        list: [...allergyHistory],
      },
    }));


  }, [allergyHistory, setData]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      allergy_history_questionnaire: {
        ...prevState.allergy_history_questionnaire,
        perscriptions: [...allergyHistoryQuiz],
      },
    }));

  }, [allergyHistoryQuiz, setData, data?.allergy_history_questionnaire?.none]);

  useEffect(() => {
    // const filteredArray = filterArray2(allergyHistoryGeneral);

    setData((prevState) => ({
      ...prevState,
      allergy_history_questionnaire: {
        ...prevState.allergy_history_questionnaire,
        questions: [...allergyHistoryGeneral],
      },
    }));


  }, [allergyHistoryGeneral, setData, data?.allergy_history_questionnaire?.none]);


  return (
    <div className="FormPage4Main flex">
      <div className="FormPage4Section1">
        <div className="sleepnessScaleSection">
          <div className="formPage4Head">
            <h2>THE EPWORTH SLEEPINESS SCALE</h2>
            <p className="italics">LA ESCALA DE SUEÑO DE EPWORTH</p>
          </div>
          <div className="sleepnessScaleDetail">
            <ul>
              <li>
                <div>
                  <p className="">
                    How likely are you to doze off or fall asleep in the
                    following scenarios in contrast to just feeling tired?
                  </p>
                  <p className="italics">
                    ¿Qué posibilidades hay de que se duerma en los siguientes
                    escenarios en contraste con solo sentirte cansado?
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="">
                    Even if have not done some of these thing recently, try to
                    work out how they would have affected you.
                  </p>
                  <p className="italics">
                    ¿Qué posibilidades hay de que se duerma en los siguientes
                    escenarios en contraste con solo sentirte cansado?
                  </p>
                </div>
              </li>
              <li>
                <div>
                  <p className="">
                    Use the scale to choose the most appropriate number for each
                    situation and circle the correct one.
                  </p>
                  <p className="italics">
                    Use la escala para elegir el número más apropiado para cada
                    situación y circule el correcto
                  </p>
                </div>
              </li>
            </ul>
            <div className="sleepnessScaleSectionMeasures">
              <p>
                <b>0 = Would Never Doze</b>
                <span className="italics">Nunca duerme</span>
              </p>
              <p>
                <b>1 = Slight Chance of Dozing</b>
                <span className="italics">
                  Poca probabilidad de quedarse dormido
                </span>
              </p>
              <p>
                <b>2 = Moderate Chance of Dozing</b>
                <span className="italics">
                  Posibilidad moderada de dormirse
                </span>
              </p>
              <p>
                <b>3 = High Chance of Dozing</b>
                <span className="italics">Alta probabilidad de dormirse</span>
              </p>
            </div>
            <div className="page4Table">
              <div className="page4TableHead flex">
                <div className="page4TableHeadPart1">
                  <h3>SCENARIO</h3>
                  <p className="italics">ESCENARIO</p>
                </div>
                <div className="page4TableHeadPart2">
                  <h3>CHANCE OF DOZING</h3>
                  <p className="italics">PROBABILIDAD DE DORMIRSE</p>
                </div>
              </div>
              {sleepnessScaleArray.map((item, index) => {
                return (
                  <div key={item.id} className="flex">
                    <div className="page4TableItem1">
                      <p>{item.condition}</p>
                      <p className="italics">{item.conditionTranslate}</p>
                    </div>
                    <div className={`page4TableItem2 flex flex-wrap ${checkValues && data?.the_epworth_sleepiness_scale[index].chanceOfDozing === "" ? "missingField" : ""}`}>
                      {rangeArray.slice(0, 4).map((rangeItem, index) => (
                        <div>
                          <div className="radio-main">
                            <input
                              type="radio"
                              name={"fp4_" + item.id}
                              className="radio-custom"
                              id={"fp4_" + item.id + "_" + index}
                              value={rangeItem.value}
                              onChange={() =>
                                handleSleepnessScaleChange(
                                  item.id,
                                  rangeItem.value
                                )
                              }
                            />
                            <label htmlFor={"fp4_" + item.id + "_" + index}>
                              {rangeItem.label}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="HearingHistorySection">
          <div className="formPage4Head">
            <h2>HEARING HISTORY QUESTIONNAIRE</h2>
            <p className="italics">CUESTIONARIO DE HISTORIA AUDITIVA</p>
            <p>Please circle the appropriate response for each symptom.</p>
            <p className="italics">
              Por favor circule la respuesta apropiada para cada síntoma
            </p>
          </div>
          <div className="HearingHistoryDetail">
            <div className="page4Table">
              {hearingHistoryArray.map((item, index) => {
                return (
                  <div key={item.id} className="flex">
                    <div className="page4TableItem1">
                      <p>{item.condition}</p>
                      <p className="italics">{item.conditionTranslate}</p>
                    </div>
                    <div

                      className={`page4TableItem2 flex flex-wrap ${checkValues && data?.hearing_history_questionnaire[index].chanceOfDozing == "" ? "missingField" : ""}`}
                    >
                      <div>
                        <div className="radio-main">
                          <input
                            type="radio"
                            name={"fp4_hearing_history" + item.id}
                            className="radio-custom"
                            id={"fp4yes" + item.id}
                            value={"Yes"}
                            onChange={() =>
                              handleHearingHistoryChange(item.id, "Yes")
                            }
                          />
                          <label htmlFor={"fp4yes" + item.id}>
                            Yes <span className="italics ">Si</span>
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="radio-main">
                          <input
                            type="radio"
                            name={"fp4_hearing_history" + item.id}
                            className="radio-custom"
                            id={"fp4no" + item.id}
                            value={"No"}
                            onChange={() =>
                              handleHearingHistoryChange(item.id, "No")
                            }
                          />
                          <label htmlFor={"fp4no" + item.id}>
                            No <span className="italics ">No</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="FormPage4Section2">
        <div className="formPage4Head flex justify-content-around">
          <div>
            <h2>ALLERGY HISTORY QUESTIONNAIRE</h2>
            <p className="italics">CUESTIONARIO DE HISTORIA DE ALERGIAS</p>
          </div>
          <div className="socialHistorySectionCheckbox activeChild">
            <input
              type="checkbox"
              name="none"
              id="none_allergy_history_questionnaire"
              checked={data.allergy_history_questionnaire.none}
              onChange={(e) => handleCheckboxChange(e)}
            />
            <label htmlFor="none_allergy_history_questionnaire">None</label>
            <p className="italics">Ninguno</p>
          </div>
        </div>
        <div className={`${data?.allergy_history_questionnaire?.none == true ? "disabledSection" : ""}`}>
          {allergyHistoryGeneral.map((item, index) => (
            <div
              key={item.id}
              className="flex align-items-center allergyHistoryBlanks"
            >
              <div style={{ width: "500px" }}>
                <p>{item.question}</p>
                <p className="italics">{item.translation}</p>
              </div>
              <input
                type="text"
                name={item.name}
                value={item.response}
                onChange={handleChangeInput}
                className={`${checkValues && !data?.allergy_history_questionnaire?.none && item.response == "" ? "missingInputField" : ""}`}
              />
            </div>
          ))}
          {/* <div className="flex align-items-center allergyHistoryBlanks">
						<div style={{ width: '500px' }}>
							<p>How long have you had allergy symptoms?</p>
							<p className="italics">
								¿Cuánto tiempo ha tenido síntomas de alergia?{' '}
							</p>
						</div>
						<input type="text" name="" />
					</div>
					<div className="flex align-items-center allergyHistoryBlanks">
						<div style={{ width: '200px' }}>
							<p>Year-round or seasonal?</p>
							<p className="italics">¿Todo el año o por temporada?</p>
						</div>
						<input />
					</div>{' '}
					<div className="flex align-items-center allergyHistoryBlanks">
						<div style={{ width: '400px' }}>
							<p>Have you been allergy tested before?</p>
							<p className="italics">
								¿Se ha hecho alguna prueba de alergia antes?
							</p>
						</div>
						<input />
					</div>{' '}
					<div className="flex align-items-center allergyHistoryBlanks">
						<div style={{ width: '500px' }}>
							<p>If yes, did you receive immunotherapy?</p>
							<p className="italics">
								En caso afirmativo, ¿recibió inmumoterapia?
							</p>
						</div>
						<input />
					</div>
					<div className="flex align-items-center allergyHistoryBlanks">
						<div style={{ width: '700px' }}>
							<p>Are you exposed to fumes, chemicals or dust at work?</p>
							<p className="italics">
								¿Esta expuesto a humos, productos químicos o polvo en el
								trabajo?
							</p>
						</div>
						<input />
					</div> */}
          <div>
            <div>
              <p>
                What prescription medication have you tried for allergies? For
                how long?
              </p>
              <p className="italics">
                ¿Qué medicamentos recetados has probado para las alergias?
              </p>
            </div>
            <div className="page4Table">
              <div className="page4TableHead flex">
                <div className="page4TableHeadPart1">
                  <h3>PRESCRIPTION</h3>
                  <p className="italics">MEDICAMENTO</p>
                </div>
                <div className="page4TableHeadPart2">
                  <h3>FOR HOW LONG</h3>
                  <p className="italics">POR CUANTO TIEMPO</p>
                </div>
              </div>

              {allergyHistoryQuiz.map((item, index) => {
                return (
                  <div key={index} className="flex">
                    <input
                      name="perscription"
                      className={`page4TableItem1 ${checkValues && index === 0 && !data?.allergy_history_questionnaire?.none && item.perscription == "" ? "missingInputField" : ""}`}
                      onChange={(event) =>
                        handleChangeAllergyQiuz(index, event)
                      }

                    />
                    <input
                      name="duration"

                      className={`page4TableItem2 ${checkValues && index === 0 && !data?.allergy_history_questionnaire?.none && item.duration == "" ? "missingInputField" : ""}`}
                      onChange={(event) =>
                        handleChangeAllergyQiuz(index, event)
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="HearingHistorySection">
              <div className="formPage4Head">
                <p>
                  Please circle the appropriate number 1-5 according to
                  severity:
                </p>
                <p className="italics">
                  Por favor circule el número apropiado 1-5 de acuerdo a la
                  severidad:
                </p>
                <p>
                  <b>0 = no problem | 1 = mild | 5 = very severe</b>
                </p>
                <p className="italics">
                  0 = No hay problema | 1 = leve | 5 = muy severo
                </p>
              </div>
              <div className="HearingHistoryDetail">
                <div className="page4Table">
                  {allergyHistory.map((item, index) => {
                    return (
                      <div key={index} className="flex">
                        <div className="page4TableItem1">
                          <p>{item.condition}</p>
                          <p className="italics">{item.conditionTranslate}</p>
                          <p>you have to fill</p>
                        </div>
                        <div className={`page4TableItem2 flex flex-wrap ${checkValues && !data?.allergy_history_questionnaire?.none && data?.allergy_history_questionnaire?.list[index].chanceOfDozing === "" ? "missingField" : ""}`}>
                          {rangeArray.map((rangeItem) => (
                            <div key={rangeItem.value}>
                              <div className="radio-main">
                                <input
                                  type="radio"
                                  name={"fp4_hear_history_detail" + item.id}
                                  className="radio-custom"
                                  id={
                                    "fp4_hear_history_detail_" +
                                    item.id +
                                    "_" +
                                    rangeItem.value
                                  }
                                  value={rangeItem.value}
                                  onChange={() =>
                                    handleAllergyHistoryChange(
                                      item.id,
                                      rangeItem.value
                                    )
                                  }
                                />
                                <label
                                  htmlFor={
                                    "fp4_hear_history_detail_" +
                                    item.id +
                                    "_" +
                                    rangeItem.value
                                  }
                                >
                                  {rangeItem.label}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default FormPage4;
