import React, { useState } from "react";
import { Button, Modal, Input, Row, Col, Radio } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./SignatureField.css";
const fontOptions = [
  "Caveat, cursive",
  "Pacifico, cursive",
  "'Marck Script', cursive",
  "Meddon, cursive",
];

const SignatureFeature = ({ onChange, name, value }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedFont, setSelectedFont] = useState(
    value?.font_family || "Caveat, cursive"
  );
  const [signatureText, setSignatureText] = useState(value?.signature || "");

  const handleFontChange = (e) => {
    setSelectedFont(e.target.value);
  };

  const handleSignatureChange = (event) => {
    const inputValue = event.target.value;
    setSignatureText(inputValue);
  };


  const inputStyle = {
    fontFamily: selectedFont,
    fontSize: "18px", // You can adjust this as needed
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    onChange(
      { signature: signatureText, font_family: selectedFont },
      name,
      true
    );
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFontSelection = (value) => {
    setSelectedFont(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleOk();
    }
  };
  return (
    <div>
      <div className="flex justify-content-between">
        <div className="w-100 cursorChange">
          <h1 onClick={showModal} style={{ fontFamily: selectedFont }} >{signatureText || "Signature"}</h1>
        </div>
      </div>
      <Modal
        title="Add Signature"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modalSign"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Input
              className="signatureInput"
              value={signatureText}
              onChange={handleSignatureChange}
              onKeyDown={handleKeyPress}
              style={inputStyle}
              placeholder="Signature"
            />
          </Col>
          <Col className="textCenter clearSing">
            <button
              onClick={() => setSignatureText("")}
              className="marginBottom"
            >
              Clear Signature
            </button>
          </Col>
        </Row>

        <Radio.Group value={selectedFont} className="signOptions">
          <Row gutter={16} justify={"space-around"}>
            {fontOptions.map((font) => (
              <Col
                sm={12}
                xs={24}
                span={24}
                className="singCol"
                onClick={(e) => handleFontSelection(font)}
              >
                <Radio
                  key={font}
                  value={font}
                  style={{ fontFamily: font }}
                  className="fontText"
                >
                  {signatureText || "Signature"}
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default SignatureFeature;
