import { Col, DatePicker, Row } from 'antd';
import { useEffect, useState } from 'react';
import { disabledDate } from '../../../utils/functions';

const FormSection3 = ({ data, setData, checkValues }) => {
	const [enableSecondaryDetails, setEnableSecondaryDetails] = useState(false);
	const [enablePrimaryDetails, setEnablePrimaryDetails] = useState(true)
	const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
		const newValue = type === "checkbox" ? checked : value;
		setData((prevState) => ({
			...prevState,
			referral_info: { ...prevState.referral_info, [name]: newValue },
		}));
	};

	const handleOtherChange = (event, field) => {
		const { name, value } = event.target;
		setData((prevState) => ({
			...prevState,
			referral_info: {
				...prevState.referral_info,
				[field]: {
					...prevState.referral_info[field],
					[name]: value,
				},
			},
		}));
	};

	useEffect(() => {
		if (data?.referral_info?.primary_insurance_detail?.primary_insurance_co
			&& data?.referral_info?.primary_insurance_detail?.policy_holder_name &&
			data?.referral_info?.primary_insurance_detail?.relationship_to_patient &&
			data?.referral_info?.primary_insurance_detail?.dob &&
			data?.referral_info?.primary_insurance_detail?.phone &&
			data?.referral_info?.primary_insurance_detail?.group
		) {
			setEnableSecondaryDetails(true)
		} else {
			setEnableSecondaryDetails(false)
		}
	}, [data?.referral_info?.primary_insurance_detail])


	const handleSelfPayChange = (event) => {
		const { name, checked } = event.target;

		setEnablePrimaryDetails(!checked)
		setData((prevState) => ({
			...prevState,
			referral_info: {
				...prevState.referral_info,
				self_pay: checked,
			},
		}));
	};

	const onChangeDate = (dateString, field) => {
		setData((prevState) => ({
			...prevState,
			referral_info: {
				...prevState.referral_info,
				[field]: {
					...prevState.referral_info[field],
					dob: dateString,
				},
			},
		}));
	};

	return (
		<>
			<div className={`${data?.referral_info?.none == true ? "disabledSection" : ""} fs3MainContainer`}>
				<div >
					<div className='flex'>
						<div>
							<h1 className="fs2Heading">REFERRAL INFORMATION:</h1>
							<p className="fs2TranslateHeading">INFORMACION REFERENCIAL</p>
						</div>
						<div className="medicationsSectionCheckbox activeChild " >
							<input
								type="checkbox"
								name="none"
								id="none_allergies"
								checked={data.referral_info.none}
								onChange={(e) => {
									handleChange(e, "referral_info");

								}}
							/>
							<label htmlFor="none_allergies">None</label>
							<p className="italics">Ninguno</p>
						</div>
					</div>

					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '49%' }}>
							<div className="fs3labels endingItem" style={{ width: '183px' }}>
								<h3>Referring Facility:</h3>
								<p className="italics">Oficina de referencia</p>
							</div>
							<input
								type="text"
								value={data.referral_info.referring_facility}
								name="referring_facility"
								onChange={handleChange}
								className={`${checkValues && !data?.referral_info?.referring_facility && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>

						<div className="fs3CustomStyling" style={{ width: '49%' }}>
							<div className="fs3labels endingItem" style={{ width: '208px' }}>
								<h3>Address / Location:</h3>
								<p className="italics">Nombre del paciente</p>
							</div>
							<input
								type="text"
								value={data.referral_info.address}
								name="address"
								onChange={handleChange}
								className={`${checkValues && !data?.referral_info?.address && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>
					</div>
					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '70%' }}>
							<div className="fs3labels endingItem" style={{ width: '322px' }}>
								<h3>Referring Provider Full Name:</h3>
								<p className="italics">
									Nombre complete dl proveedor de referencia
								</p>
							</div>
							<input
								type="text"
								value={data.referral_info.referring_provider_name.name}
								name="name"
								onChange={(event) =>
									handleOtherChange(event, 'referring_provider_name')
								}
								className={`${checkValues && !data?.referral_info?.referring_provider_name?.name && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>
						<div className="fs3CustomStyling" style={{ width: '28%' }}>
							<div className="fs3labels endingItem" style={{ width: '80px' }}>
								<h3>Phone:</h3>
								<p className="italics">Teléfono</p>
							</div>
							<input
								type="number"
								value={data.referral_info.referring_provider_name.phone}
								min={0}
								name="phone"
								onChange={(event) =>
									handleOtherChange(event, 'referring_provider_name')
								}
								className={`${checkValues && !data?.referral_info?.referring_provider_name?.phone && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>
					</div>

					<div className="fs3Container">
						<div className="fs3CustomStyling " style={{ width: '70%' }}>
							<div className="fs3labels endingItem" style={{ width: '405px' }}>
								<h3>Primary Care Physician Full Name:</h3>
								<p className="italics">
									Nombre complete del medico de atencion primaria
								</p>
							</div>
							<input
								type="text"
								value={data.referral_info.primarycare_provider_name.name}
								name="name"
								onChange={(event) =>
									handleOtherChange(event, 'primarycare_provider_name')
								}
								className={`${checkValues && !data?.referral_info?.primarycare_provider_name?.name && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>
						<div className="fs3CustomStyling" style={{ width: '28%' }}>
							<div className="fs3labels endingItem" style={{ width: '80px' }}>
								<h3>Phone:</h3>
								<p className="italics">Teléfono</p>
							</div>
							<input
								type="number"
								value={data.referral_info.primarycare_provider_name.phone}
								min={0}
								name="phone"
								onChange={(event) =>
									handleOtherChange(event, 'primarycare_provider_name')
								}
								className={`${checkValues && !data?.referral_info?.primarycare_provider_name?.phone && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>
					</div>

					<div className="fs3Conatiner fs3CheckBox">
						<div>
							<input
								type="checkbox"
								name="self_pay"
								className="radio-custom"
								id={'self_pay'}
								checked={data.referral_info.selfPay}
								onChange={handleSelfPayChange}
							/>
							<label htmlFor="self_pay" style={{ fontSize: '1rem' }}>
								Self Pay
							</label>
						</div>
					</div>
					<div className="fs3TranslatorText">Sin Seguro</div>
					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '70%' }}>
							<div className="fs3labels endingItem" style={{ width: '740px' }}>
								<h3>Responsible Party/Guardian (if patient is a minor):</h3>
								<p className="italics">
									Persona responsible (Si el paciente es menor de edad)
								</p>
							</div>
							<input
								type="text"
								value={data.referral_info.responsible_party_guardian.guardian}
								name="guardian"
								onChange={(event) =>
									handleOtherChange(event, 'responsible_party_guardian')
								}
								className={`${checkValues && !data?.referral_info?.responsible_party_guardian?.guardian && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>
						<div className="fs3CustomStyling" style={{ width: '28%' }}>
							<div className="fs3labels endingItem" style={{ width: '80px' }}>
								<h3>Phone:</h3>
								<p className="italics">Teléfono</p>
							</div>
							<input
								type="number"
								value={data.referral_info.responsible_party_guardian.phone}
								min={0}
								name="phone"
								onChange={(event) =>
									handleOtherChange(event, 'responsible_party_guardian')
								}
								className={`${checkValues && !data?.referral_info?.responsible_party_guardian?.phone && !data?.referral_info?.none ? "missingInputField" : ""}`}
							/>
						</div>
					</div>
				</div>
				<div className={` ${!enablePrimaryDetails ? "disabledSection" : ""}`}>
					<h3 className="fs3HeadingInsurance">Primary Insurance Detail</h3>
					<p className="fs3TranslatorHI">Seguro Primario</p>

					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '60%' }}>
							<div className="fs3labels endingItem" style={{ width: '300px' }}>
								<h3>PRIMARY INSURANCE CO:</h3>
								<p className="italics">Seguro Primario</p>
							</div>
							<input
								type="text"
								value={
									data.referral_info.primary_insurance_detail.primary_insurance_co
								}
								name="primary_insurance_co"
								onChange={(event) =>
									handleOtherChange(event, 'primary_insurance_detail')
								}
								className={`${checkValues && enablePrimaryDetails && !data?.referral_info?.none && !data?.referral_info?.primary_insurance_detail?.primary_insurance_co ? "missingInputField" : ""}`}
							/>
						</div>
						<div className="fs3CustomStyling" style={{ width: '38%' }}>
							<div className="fs3labels endingItem" style={{ width: '80px' }}>
								<h3>Phone:</h3>
								<p className="italics">Teléfono</p>
							</div>
							<input
								type="number"
								value={data.referral_info.primary_insurance_detail.phone}
								min={0}
								name="phone"
								onChange={(event) =>
									handleOtherChange(event, 'primary_insurance_detail')
								}
								className={`${checkValues && enablePrimaryDetails && !data?.referral_info?.none && !data?.referral_info?.primary_insurance_detail?.phone ? "missingInputField" : ""}`}
							/>
						</div>
					</div>

					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '60%' }}>
							<div className="fs3labels endingItem" style={{ width: '205px' }}>
								<h3>Policy Holder Name:</h3>
								<p className="italics">Nombre del titular de la póliza</p>
							</div>
							<input
								type="text"
								value={
									data.referral_info.primary_insurance_detail.policy_holder_name
								}
								name="policy_holder_name"
								onChange={(event) =>
									handleOtherChange(event, 'primary_insurance_detail')
								}
								className={`${checkValues && enablePrimaryDetails && !data?.referral_info?.none && !data?.referral_info?.primary_insurance_detail?.policy_holder_name ? "missingInputField" : ""}`}
							/>
						</div>
						<div className="fs3CustomStyling" style={{ width: '38%' }}>
							<div className="fs3labels endingItem" style={{ width: '95px' }}>
								<h3>Group #:</h3>
								<p className="italics">Número de grupo</p>
							</div>
							<input
								type="text"
								value={data.referral_info.primary_insurance_detail.group}
								name="group"
								onChange={(event) =>
									handleOtherChange(event, 'primary_insurance_detail')
								}
								className={`${checkValues && enablePrimaryDetails && !data?.referral_info?.none && !data?.referral_info?.primary_insurance_detail?.group ? "missingInputField" : ""}`}
							/>
						</div>
					</div>

					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '67%' }}>
							<div className="fs3labels endingItem" style={{ width: '260px' }}>
								<h3>Relationship to Patient:</h3>
								<p className="italics">Relación con el paciente</p>
							</div>
							<input
								type="text"
								value={
									data.referral_info.primary_insurance_detail
										.relationship_to_patient
								}
								className={`${checkValues && enablePrimaryDetails && !data?.referral_info?.none && !data?.referral_info?.primary_insurance_detail?.relationship_to_patient ? "missingInputField" : ""}`}
								name="relationship_to_patient"
								onChange={(event) =>
									handleOtherChange(event, 'primary_insurance_detail')
								}
							/>
						</div>

						<div className="fs3DOBContainer">
							<div className="fs3labels endingItem">
								<h3 className="fs2DOBText">DOB</h3>
								<div className="fs2DOBTranslatorText">Fecha de nacimiento</div>
							</div>
							<DatePicker
								className={`${checkValues && enablePrimaryDetails && !data?.referral_info?.none && !data?.referral_info?.primary_insurance_detail?.dob ? "missingInputField" : ""} fs2dateStyle`}
								onChange={(date, dateString) =>
									onChangeDate(dateString, 'primary_insurance_detail')
								}
								disabledDate={disabledDate}
								name="dob"
							/>
						</div>
					</div>
				</div>
				<div className={`secondary_container ${!enableSecondaryDetails ? "disabledSection" : ""}`} >
					<h3 className="fs3HeadingInsurance">Secondary Insurance Detail</h3>
					<p className="fs3TranslatorHI">Seguro Secundario</p>

					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '60%' }}>
							<div className="fs3labels endingItem" style={{ width: '300px' }}>
								<h3>SECONDARY INSURANCE CO:</h3>
								<p className="italics">Seguro Primario</p>
							</div>
							<input
								type="text"
								value={
									data.referral_info.secondary_insurance_detail &&
									data.referral_info.secondary_insurance_detail
										.secondary_insurance_co
								}
								name="secondary_insurance_co"
								onChange={(event) =>
									handleOtherChange(event, 'secondary_insurance_detail')
								}

							/>
						</div>
						<div className="fs3CustomStyling" style={{ width: '38%' }}>
							<div className="fs3labels endingItem" style={{ width: '80px' }}>
								<h3>Phone:</h3>
								<p className="italics">Teléfono</p>
							</div>
							<input
								type="number"
								value={data.referral_info.secondary_insurance_detail.phone}
								min={0}
								name="phone"
								onChange={(event) =>
									handleOtherChange(event, 'secondary_insurance_detail')
								}

							/>
						</div>
					</div>

					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '60%' }}>
							<div className="fs3labels endingItem" style={{ width: '205px' }}>
								<h3>Policy Holder Name:</h3>
								<p className="italics">Nombre del titular de la póliza</p>
							</div>
							<input
								type="text"
								value={
									data.referral_info.secondary_insurance_detail.policy_holder_name
								}
								name="policy_holder_name"
								onChange={(event) =>
									handleOtherChange(event, 'secondary_insurance_detail')
								}

							/>
						</div>
						<div className="fs3CustomStyling" style={{ width: '38%' }}>
							<div className="fs3labels endingItem" style={{ width: '95px' }}>
								<h3>Group #:</h3>
								<p className="italics">Número de grupo</p>
							</div>
							<input
								type="text"
								value={data.referral_info.secondary_insurance_detail.group}
								name="group"
								onChange={(event) =>
									handleOtherChange(event, 'secondary_insurance_detail')
								}

							/>
						</div>
					</div>

					<div className="fs3Container">
						<div className="fs3CustomStyling" style={{ width: '67%' }}>
							<div className="fs3labels endingItem" style={{ width: '260px' }}>
								<h3>Relationship to Patient:</h3>
								<p className="italics">Relación con el paciente</p>
							</div>
							<input
								type="text"
								value={
									data.referral_info.secondary_insurance_detail
										.relationship_to_patient
								}
								name="relationship_to_patient"
								onChange={(event) =>
									handleOtherChange(event, 'secondary_insurance_detail')
								}

							/>
						</div>

						<div className="fs3DOBContainer">
							<div className="fs3labels .fs3labels">
								<h3 className="fs2DOBText">DOB</h3>
								<div className="fs2DOBTranslatorText">Fecha de nacimiento</div>
							</div>
							<DatePicker
								className={` fs2dateStyle`}
								onChange={(date, dateString) =>
									onChangeDate(dateString, 'secondary_insurance_detail')
								}
								name="dob"
								disabledDate={disabledDate}
							/>
						</div>
					</div>
				</div>
				<div className="divider" />
			</div>
		</>
	);
};

export default FormSection3;
