import { Col, Row } from "antd";
import { useEffect, useState } from "react";
const rangeArray = [
  { value: 0, id: 0, label: "0" },
  { value: 1, id: 1, label: "1" },
  { value: 2, id: 2, label: "2" },
  { value: 3, id: 3, label: "3" },
  { value: 4, id: 4, label: "4" },
  { value: 5, id: 5, label: "5" },
];

const rangeChoic = [
  {
    condition: "Pressure in the ears",
    range: null,
    translation: "Presión en los oídos",
    name: "pressureEarsRange",
    id: 1,
  },
  {
    condition: "Pain in the ears",
    range: null,
    translation: "Dolor en los oídos",
    name: "pressureEarsPainRange",
    id: 2,
  },
  {
    condition: "Ears feel clogged or underwater",
    range: null,
    translation: "Las orejas se sienten obstuidas o bajo el agua",
    name: "pressureCloggedRange",
    id: 3,
  },
  {
    condition: "Ear problems when you have a cold or sinusitis",
    range: null,
    translation: "Problemas de oído cuando tiene un resfriado o sinusitis",
    name: "pressureEarsProblemRange",
    id: 4,
  },

  {
    condition: "Crackling or popping sounds",
    range: null,
    translation: "Sonidos de crujidos o estallidos",
    name: "cracklingRange",
    id: 5,
  },
  {
    condition: "Ringing in the ears",
    range: null,
    translation: "Zumbido en los oídos",
    name: "ringingRange",
    id: 6,
  },
  {
    condition: "Muffled feeling in ears",
    range: null,
    translation: "Sensación amortiguada en los oídos",
    name: "muffledRange",
    id: 7,
  },
];
const FormSection7 = ({ data, setData, reviewSymptoms, setReviewSymptoms, checkValues }) => {
  const [tubeDysfunctions, setTubeDysfunctions] = useState(rangeChoic);

  const handleSelectionChange = (id, value) => {
    const updatedMultiChoice = reviewSymptoms.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          selected: value,
        };
      }
      return item;
    });

    setReviewSymptoms(updatedMultiChoice);
  };

  const handleChangeRadio = (event, field) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;

    setData((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [name]: newValue,
      },
    }));
  };

  const handleTubeDysfunctionsChange = (id, value) => {
    const updatedMultiChoice = tubeDysfunctions.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          selected: value,
        };
      }
      return item;
    });

    setTubeDysfunctions(updatedMultiChoice);
    let allEarProblems = updatedMultiChoice;
    let totalScore = 0;
    allEarProblems?.forEach((item) => {

      totalScore = item.selected ? totalScore + parseInt(item.selected) : totalScore;
    });
    setData((prevState) => ({
      ...prevState,
      eustachian_tube_dysfunction: {
        ...prevState.eustachian_tube_dysfunction,
        totalScore: totalScore,
        itemScore: totalScore + 7,
      },
    }));
  };

  // function filterArray(array) {
  // 	return array.map(({ condition, selected }) => ({ condition, selected }));
  // }
  // function filterArray2(array) {
  // 	return array.map(({ condition, selected }) => ({ condition, selected }));
  // }

  useEffect(() => {
    // const filteredArray = filterArray(reviewSymptoms);

    setData((prevState) => ({
      ...prevState,
      review_of_symptoms2: [...reviewSymptoms],
    }));


  }, [reviewSymptoms, setData]);

  useEffect(() => {
    // const filteredArray = filterArray2(tubeDysfunctions);

    setData((prevState) => ({
      ...prevState,
      eustachian_tube_dysfunction: {
        ...prevState.eustachian_tube_dysfunction,
        list: [...tubeDysfunctions],
      },
    }));

  }, [tubeDysfunctions, setData]);

  return (
    <>
      {reviewSymptoms.map((item, index) => {
        return (

          <div className="fs5RadioContainer" key={index} >
            <h3 className="fs5RadioHeading">
              {item.condition}
              <span className="fs5RadioHeadingTranslation">
                {item.translation}
              </span>
            </h3>
            <div className="fs5CheckboxContainer">
              <div className={`flex flex-wrap ml-2 ${checkValues && !data?.review_of_symptoms2[index].selected ? "missingField" : ""} `} style={{ width: "95%" }}>
                <div>
                  <div className="radio-main">
                    <input
                      type="radio"
                      name={"review_symptoms2" + item.id}
                      className="radio-custom"
                      id={"fs7yes" + item.id}
                      value={"Yes"}
                      onChange={() => handleSelectionChange(item.id, "Yes")}
                      checked={item.selected === "Yes"}
                    />
                    <label htmlFor={"fs7yes" + item.id}>
                      Yes <span className="italics ">Si</span>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="radio-main">
                    <input
                      type="radio"
                      name={"review_symptoms2" + item.id}
                      className="radio-custom"
                      id={"fs7no" + item.id}
                      value={"No"}
                      checked={item.selected === "No"}
                      onChange={() => handleSelectionChange(item.id, "No")}
                    />
                    <label htmlFor={"fs7no" + item.id}>
                      No <span className="italics ">No</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        );
      })}
      <div className="fs5Border" />

      <div>
        <div className="fs7TextContainer">
          <h3 className="fs7MainHeading">EUSTACHIAN TUBE DYSFUNCTION</h3>
          <p className="fs4HeadingTranslator">
            DISFUNCION DEL TUBO EUSTACHIANO
          </p>
          <p className="fs7Heading">
            During the last month, how much of a problem was each of the
            following:{" "}
          </p>
          <p className="fs4HeadingTranslator">
            Durante el ultimo mes, que tan problematico fue cada uno de los
          </p>
          <p className="fs7RangeText">
            0 = no problem <span className="fs7spanBorder">|</span> 2 = moderate
            problem <span className="fs7spanBorder">|</span> 5 = very severe
          </p>
          <p className="fs7TranslationText">
            0 = no hay problema <span className="fs7spanBorder" /> 2 = problema
            moderato <span className="fs7spanBorder" /> 5 = muy severo
          </p>
        </div>
      </div>
      <div className="fs7ScoreTable">
        {tubeDysfunctions.map((item, index) => {
          return (
            <div>
              <div key={index} className="flex">
                <div className="page4TableItem1">
                  <p>{item.condition}</p>
                  <p className="italics">{item.translation}</p>
                </div>
                <div className={`page4TableItem2 flex flex-wrap ${checkValues && !(data?.eustachian_tube_dysfunction.list[index].selected || data?.eustachian_tube_dysfunction.list[index].selected == 0) ? "missingField" : ""} `} >
                  {rangeArray.map((rangeItem) => (
                    <div key={rangeItem.value}>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name={"fs7_hear_history_detail" + item.id}
                          className="radio-custom"
                          id={
                            "fs7_hear_history_detail_" +
                            item.id +
                            "_" +
                            rangeItem.value
                          }
                          value={rangeItem.value}
                          onChange={() =>
                            handleTubeDysfunctionsChange(
                              item.id,
                              rangeItem.value
                            )
                          }
                        />
                        <label
                          htmlFor={
                            "fs7_hear_history_detail_" +
                            item.id +
                            "_" +
                            rangeItem.value
                          }
                        >
                          {rangeItem.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <Row className="fs7ScoreCalculation" >
        <Col md={6} sm={12} xs={24}>
          <h3 className="fs7TotalHeading">Total Score </h3>
          <p className="italics">Puntuación total</p>
        </Col>
        <Col md={6} sm={12} xs={24} className="fs7InputFieldContainer">
          <input
            type="number"
            name={"totalScore"} // Same name attribute for all radio inputs
            min={0}

            className={`w-100 numberInput`}
            id={"totalScore"} // Unique id attribute
            value={data.eustachian_tube_dysfunction.totalScore}

          />
        </Col>
        <Col md={6} sm={12} xs={24} >
          <h3 className="fs7TotalHeading">+ 7 = Mean Item Score </h3>
          <p className="italics">+ 7 = Puntuación de elementos medios</p>
        </Col>
        <Col md={6} sm={12} xs={24} className="fs7InputFieldContainer">
          <input
            type="number"
            name={"itemScore"} // Same name attribute for all radio inputs
            min={0}
            className={`w-100 numberInput ${checkValues && !data?.eustachian_tube_dysfunction.itemScore ? "missingInputField" : ""} `}
            id={"itemScore"} // Unique id attribute
            value={data.eustachian_tube_dysfunction.itemScore}

          />
        </Col>
      </Row>

      <div className="fs7SymptomsContainer">
        <div className="fs7SymptomsText">
          <h3>Are these symptoms in :</h3>
          <p className="italics">¿Son estos sintomas en el oido</p>
        </div>

        <div className={`fs7SymptomsContainerCheckboxes flex ml-1 ${checkValues && !data?.eustachian_tube_dysfunction?.disfunctions_in ? "missingField" : ""}`}>
          <div>
            <div className="radio-main">
              <input
                type="radio"
                name={"disfunctions_in"} // Same name attribute for all radio inputs
                className="radio-custom"
                id={"fs7symptoms_1"} // Unique id attribute
                value={"Left ear only"}
                onChange={(e) =>
                  handleChangeRadio(e, "eustachian_tube_dysfunction")
                }
              />
              <label htmlFor={"fs7symptoms_1"}>Left ear only</label>
            </div>
          </div>
          <div>
            <div className="radio-main">
              <input
                type="radio"
                name={"disfunctions_in"} // Same name attribute for all radio inputs
                className="radio-custom"
                id={"fs7symptoms_2"} // Unique id attribute
                value={"Right ear only"}
                onChange={(e) =>
                  handleChangeRadio(e, "eustachian_tube_dysfunction")
                }
              />
              <label htmlFor={"fs7symptoms_2"}>Right ear only</label>
            </div>
          </div>
          <div>
            <div className="radio-main">
              <input
                type="radio"
                name={"disfunctions_in"} // Same name attribute for all radio inputs
                className="radio-custom"
                id={"fs7symptoms_3"} // Unique id attribute
                value={"Both ears"}
                onChange={(e) =>
                  handleChangeRadio(e, "eustachian_tube_dysfunction")
                }
              />
              <label htmlFor={"fs7symptoms_3"}>Both ears</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSection7;
