import React, { useEffect } from "react";
import { Row, Col, DatePicker } from "antd";
import "./FormPage6.css";
import "../FormSections.css";
import Footer from "./FormSection9";
import SignatureFeature from "../../SignatureField/SignatureField";
const FormPage6 = ({ data, setData, checkValues }) => {
  const handleChange = (e, Name, noValue) => {
    const key = noValue ? Name : e?.target?.name;
    const value = noValue ? e : e?.target?.value;
    setData({
      ...data,
      patient_financial_policy: {
        ...data.patient_financial_policy,
        [key]: value,
      },
    });
  };

  return (
    <div className="formPage6Main">
      <h3 className="heading">PATIENT FINANCIAL POLICY </h3>
      <h5>
        You will be responsible for payment of all services if any of the
        following circumstances apply:
      </h5>
      <ul className="mx">
        <li>
          If you do not have insurance or failed to notify us that your
          insurance has terminated or changed;
        </li>
        <li>
          If you do not have a referral and/or authorization when required and
          have elected to be seen;
        </li>
        <li>
          If you are with an insurance company we are not contracted with; or,
        </li>
        <li>
          If a claim denial from the insurance company, for any reason, is not
          able to be resolved.
        </li>
      </ul>
      <div className="marginTop1">
        <p>
          <h5>Valid Insurance:</h5>
          Insurance companies require the submission of all claims within a
          specified time limit. If you have changes in your insurance coverage,
          and you fail to inform us of the change within twenty-one days of your
          visit, you may be responsible for the charges. Denials often arrive
          after the filing limit has expired, thus preventing us from being able
          to re-file a new claim with your new carrier. To ensure that you are
          not responsible for the charges, please make sure that we always have
          up-to- date information regarding your insurance coverage. Again, any
          denied claims for lack of correct insurance information, will be
          applied to patient responsibility.{" "}
        </p>
      </div>
      <div className="marginTop1">
        <p>
          <h5>Referrals:</h5> If your plan requires a referral from your primary
          care physician, it is YOUR responsibility to obtain it prior to your
          appointment and have it with you at the time of your visit. If your
          plan requires a referral and you do not obtain one, you will be held
          responsible for the visit charges in full at the time of service
        </p>
      </div>
      <div className="marginTop1">
        <p>
          <h5>Coverage:</h5> An attempt will be made to obtain and comply with
          insurance company requirements. However, it is ultimately YOUR
          responsibility as a patient to verify your plan benefits, and whether
          or not Enticare PC is a contracted provider, prior to having any
          services rendered. Any and all face-to-face encounters with an
          Enticare provider qualifies as an office visit and will result in a
          claim submission to your insurance carrier. Co-payment, co-insurance
          and deductible amounts will apply and are the responsibility of the
          patient.
        </p>
      </div>
      <div className="marginTop1">
        <p>
          <h5>Disputes:</h5> : Co-payment, co-insurance, and deductible amounts
          MUST be paid at the time of service. Any account balances will also be
          collected at the time of your visit. Any unpaid or disputed balances
          must be resolved within 90 days from the date of service. Enticare PC
          reserves the right to turn accounts over to a third party collection
          agency after 90 days. The responsible party or guarantor of this
          account will be responsible for all collection fees, legal fees, and
          any other fees associated with the account. If you have any questions,
          contact Enticare PC’s Billing Department at{" "}
          <b>Billing@enticare.com</b>.
        </p>
      </div>
      <p className="marginTop1">
        A $35.00 fee will be applied to all returned checks or disputed credit
        card transactions.
      </p>
      <div className="marginTop1">
        <p>
          <h5>Medical Records:</h5> A fee of $1.00 per page up to $25.00 will be
          charged to patients requesting medical records for personal use,
          disability or non-surgical reasons. A records request form must be
          signed and received by Enticare PC before any records are disbursed
          per HIPAA. Please allow up to 14 business days.{" "}
        </p>
      </div>
      <div className="marginTop1">
        <h5>No Show / Late Cancellation of Appointments:</h5>
        <p>To avoid a $25 no show fee you must:</p>
        <ul>
          <li>
            Give at least 24-hour notice of your inability to make it to your
            clinical appointment.
          </li>
        </ul>
        <p>
          To avoid a $150 no show fee related to scheduled sleep studies,
          allergy testing, and or hearing testing, you must:
        </p>
        <ul>
          <li>
            {" "}
            Give at least 72-hour notice of your inability to attend your
            study/test
          </li>
        </ul>
        <p>To avoid a $150 no show related to surgery you must:</p>
        <ul>
          <li>
            Give at least 5 business days notice of your need to modify your
            appointment
          </li>
        </ul>
      </div>
      <p>
        In the event you missed three separate appointments without giving us
        the required notice, Enticare PC has the ability to discharge you as an
        active patient from our practice. In the event that your insurance plan
        does not allow us to charge a no show fee, Enticare PC holds as recourse
        the ability to discharge you as an active patient due to your inability
        to keep scheduled appointments
      </p>
      <div className="marginTop1">
        <p>
          <h5>Dismissal from Practice:</h5>
          Enticare PC reserves the right to dismiss patients who are not
          compliant with any one of the following:{" "}
        </p>
        <ul>
          <li>Refusal to comply with recommendations from the provider;</li>
          <li>Does not comply with office policies;</li>
          <li> Refuses to cooperate with staff;</li>
          <li>
            Repeatedly disputes fees that are fair and are consistent with the
            services provided;
          </li>
          <li>
            Displays threatening, hostile attitude or behavior to physicians or
            staff;
          </li>
          <li>
            Continues to abuse prescription drugs or controlled substances after
            physician intervention;
          </li>
          <li>Refuses to pay outstanding balances;</li>
          <li>
            3 no-shows or other non-compliance issues that interfere or
            jeopardize patient treatment or safety;
          </li>
          <li>
            Breakdown of communication with patient and/or family resulting in a
            lack of trust that makes it medically impossible to treat the
            patient
          </li>
        </ul>
      </div>
      <div>
        <h5>
          By signing this form, you agree to all the information listed above,
          authorize the release of any medical information necessary to process
          your claims and authorize payment of medical benefits to Enticare PC,
          or supplier for services rendered.
        </h5>
      </div>
      <Row gutter={16}>
        <Col md={9} sm={16} xs={24} className="marginTop7 textCenter">
          <SignatureFeature
            name={"patient_financial_signature"}
            onChange={handleChange}
            value={data?.patient_financial_policy?.patient_financial_signature}
          />
          <div className={`marginTop borderTop signTextOver ${checkValues && !data?.patient_financial_policy?.patient_financial_signature?.signature ? "missingFieldSpecial" : ""}`}>
            Signature of Patient, Parent, or Legal Guardian
          </div>
        </Col>
        <Col
          md={9}
          sm={8}
          xs={24}
          className="marginTop7 textCenter signSibling inputParent"
        >
          <input
            name="patient_financial_name"
            type="text"

            className={`w-100 ${checkValues && !data?.patient_financial_policy?.patient_financial_name ? "missingInputField" : ""}`}
            onChange={(e) => handleChange(e, "patient_financial_name")}
            value={data?.patient_financial_policy?.patient_financial_name}
          />
          <div className="w-100 marginTop"> Print Name</div>
        </Col>
        <Col md={0} sm={8} xs={0}></Col>
        <Col
          md={6}
          sm={8}
          xs={24}
          className="marginTop7 textCenter signSibling"
        >
          <DatePicker

            className={`specialDatePicker fs2dateStyle ${checkValues && !data?.patient_financial_policy?.patient_financial_date ? "missingInputField" : ""}`}
            name="patient_financial_date"
            onChange={(date, dateString) =>
              handleChange(dateString, "patient_financial_date", true)
            }

          />
          <div className="w-100 marginTop "> Date</div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default FormPage6;
