import { Col, DatePicker, Row } from "antd";
import React, { useEffect } from "react";
import "./FormPage7.css";
import "../FormSections.css";
import Footer from "../FormPage6/FormSection9";
import SignatureFeature from "../../SignatureField/SignatureField";
const FormPage7 = ({ data, setData, checkValues }) => {



  const handleChange = (e, Name, noValue) => {
    const key = noValue ? Name : e?.target?.name;
    const value = noValue ? e : e?.target?.value;

    setData({
      ...data,
      medication_history_consent: {
        ...data?.medication_history_consent,
        [key]: value,
      },
    });
  };

  const handleChangeSecond = (e, Name, noValue) => {
    const key = noValue ? Name : e?.target?.name;
    const value = noValue ? e : e?.target?.value;
    setData({
      ...data,
      communication_consent: {
        ...data?.communication_consent,
        [key]: value,
      },
    });
  };

  return (
    <div className="formPage7Main">
      <h3 className="heading">MEDICATION HISTORY CONSENT FORM</h3>
      <p>
        By signing below I give permission for <b>Enticare, PC</b> to access my
        pharmacy benefits data electronically through RxHub. This consent will
        enable <b> Enticare, PC </b> to:
      </p>
      <ul>
        <li>
          Determine the pharmacy benefits and drug co pays for a patient's
          health plan.
        </li>
        <li>
          {" "}
          Check whether a prescribed medication is covered (in formulary) under
          a patient's plan.
        </li>
        <li>
          {" "}
          Determine if a patient's health plan allows electronic prescribing to
          Mail Order pharmacies, and if so, e-prescribe to these pharmacies.
        </li>
        <li>
          Download a historic list of all medications prescribed for a patient
          by any provider.
        </li>
      </ul>
      <p>
        In summary, we ask your permission to obtain formulary information, and
        information about other prescriptions prescribed by other providers
        using RxHub.
      </p>
      <Row>
        <Col sm={10} xs={24} className="marginTop7 textCenter">
          <input
            name="patient_consent_name"

            className={`w-100 ${checkValues && !data?.medication_history_consent?.patient_consent_name ? "missingInputField" : ""}`}
            type="text"
            onChange={(e) => handleChange(e, "patient_consent_name")}
            value={data?.medication_history_consent?.patient_consent_name}
          />
          <div className=" marginTop">Patient Name (Print)</div>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24} className="marginTop7 textCenter">
          <SignatureFeature
            name={"patient_financial_signature"}
            onChange={handleChange}
            value={
              data?.medication_history_consent?.patient_financial_signature
            }
          />
          <div className={`marginTop borderTop ${checkValues && !data?.medication_history_consent?.patient_financial_signature?.signature ? "missingFieldSpecial" : ""} `}>Patient Signature Date</div>
        </Col>
        <Col sm={7} xs={0}></Col>
        <Col sm={7} xs={24} className="marginTop7 textCenter signSibling">
          <DatePicker

            className={`specialDatePicker fs2dateStyle ${checkValues && !data?.medication_history_consent?.medication_consent_date ? "missingInputField" : ""}`}
            name="medication_consent_date"
            onChange={(date, dateString) =>
              handleChange(dateString, "medication_consent_date", true)
            }

          />
          <div className=" marginTop"> Date</div>
        </Col>
      </Row>
      <div className="divider dividerCustom" />
      <h3 className="heading">COMMUNICATION CONSENT FORM</h3>
      <p>
        By signing below, I give Enticare PC permission to communicate with me
        via the e-mail given on the new patient paperwork. I understand that the
        purpose of any such communication will be for educational information,
        access to our portal, and for news related to our practice and or your
        provider that may affect you.
      </p>

      <Row>
        <Col sm={10} xs={24} className="marginTop7 textCenter">
          <input
            name="communication_patient_name"
            type="text"
            className={`w-100 ${checkValues && !data?.communication_consent?.communication_patient_name ? "missingInputField" : ""}`}
            onChange={(e) =>
              handleChangeSecond(e, "communication_patient_name")
            }
            value={data?.communication_consent?.communication_patient_name}
          />
          <div className=" marginTop">Patient Name (Print)</div>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24} className="marginTop7 textCenter">
          <SignatureFeature
            name={"communication_consent_signature"}
            onChange={handleChangeSecond}
            value={data?.communication_consent?.communication_consent_signature}
          />
          <div className={`marginTop borderTop ${checkValues && !data?.communication_consent?.communication_consent_signature?.signature ? "missingFieldSpecial" : ""}`}>Patient Signature Date</div>
        </Col>
        <Col sm={7} xs={0}></Col>
        <Col sm={7} xs={24} className="marginTop7 textCenter signSibling">
          <DatePicker
            className={`specialDatePicker fs2dateStyle ${checkValues && !data?.communication_consent?.communication_consent_date ? "missingInputField" : ""}`}
            name="communication_consent_date"
            onChange={(date, dateString) =>
              handleChangeSecond(dateString, "communication_consent_date", true)
            }
          />
          <div className=" marginTop"> Date</div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default FormPage7;
