import FormPage2 from "../FormSections/FormPage2/FormPage2";
import FormPage4 from "../FormSections/FormPage4/FormPage4";
import FormPage3 from "../FormSections/FormPage3/FormPage3";
import FormPage1 from "../FormSections/FormPage1/FormPage1";
import FormPage5 from "../FormSections/FormPage5/FormPage5";
import FormPage6 from "../FormSections/FormPage6/FormPage6";
import FormPage7 from "../FormSections/FormPage7/FormPage7";
import FormPage8 from "../FormSections/FormPage8/FormPage8";
import "./FormUrlComponent.css";

const FormUrlComponent = ({ data, setData, checkValues }) => {
  return (
    <>
      <div>
        <FormPage1 data={data} setData={setData} checkValues={checkValues} />
        <FormPage2 data={data} setData={setData} checkValues={checkValues} />
        <FormPage3 data={data} setData={setData} checkValues={checkValues} />
        <FormPage4 data={data} setData={setData} checkValues={checkValues} />
        <FormPage5 data={data} setData={setData} checkValues={checkValues} />
        <FormPage6 data={data} setData={setData} checkValues={checkValues} />
        <FormPage7 data={data} setData={setData} checkValues={checkValues} />
        <FormPage8 data={data} setData={setData} checkValues={checkValues} />
      </div>
    </>
  );
};

export default FormUrlComponent;
