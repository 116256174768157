import axios from "axios";

const backEndURLWithoutAuth = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  // baseURL: "https://275a-119-73-112-134.ngrok-free.app/",
});

export const postApiWithoutAuth = async (url, body) => {
  try {
    const result = await backEndURLWithoutAuth.post(url, body);
    return result;
  } catch (error) {
    return error.response?.data;
  }
};
