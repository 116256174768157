import { useEffect, useState } from "react";
import "./FormPage2.css";
import { Checkbox } from "antd";

const familyHistoryArr = [
  {
    condition: "Problems with anesthesia",
    name: "problems_with_anesthesia",
    translation: "Problemas con la anestesia",
    id: 1,
    relationship: "",
  },
  {
    condition: "Thyroid disease",
    name: "thyroid_disease",
    translation: "Enfermedad de la tiroides",
    id: 2,
    relationship: "",
  },
  {
    condition: "Thyroid cancer",
    name: "thyroid_cancer",
    translation: "Cáncer de tiroides",
    id: 3,
    relationship: "",
  },
  {
    condition: "Throat cancer",
    name: "throat_cancer",
    translation: "Cáncer de garganta",
    id: 4,
    relationship: "",
  },
  {
    condition: "Other cancer",
    name: "other_cancer",
    translation: "Otro tipo de cáncer",
    id: 5,
    relationship: "",
  },
  {
    condition: "Early hearing loss",
    name: "early_hearing_loss",
    translation: "Pérdida temprana de la audición",
    id: 6,
    relationship: "",
  },
  {
    condition: "Heart disease",
    name: "heart_disease",
    translation: "Enfermedad cardíaca",
    id: 7,
    relationship: "",
  },
  {
    condition: "High blood pressure",
    name: "high_blood_pressure",
    translation: "Presión arterial alta",
    id: 8,
    relationship: "",
  },
  {
    condition: "Diabetes",
    name: "diabetes",
    translation: "Diabetes",
    id: 9,
    relationship: "",
  },
  {
    condition: "Stroke",
    name: "stroke",
    translation: "Accidente cerebrovascular",
    id: 10,
    relationship: "",
  },
  {
    condition: "Asthma",
    name: "asthma",
    translation: "Asma",
    id: 11,
    relationship: "",
  },
  {
    condition: "Kidney problems",
    name: "kidney_problems",
    translation: "Problemas renales",
    id: 12,
    relationship: "",
  },
  {
    condition: "Bleeding / Clotting problems",
    name: "bleeding_clotting_problems",
    translation: "Problemas de sangrado / coagulación",
    id: 13,
    relationship: "",
  },
  {
    condition: "Other",
    name: "other",
    translation: "Otro",
    id: 14,
    relationship: "",
  },
];

const allergyHistoryQuestionsArr = [
  { procedure: "", time: "", id: 1 },
  { procedure: "", time: "", id: 2 },
  { procedure: "", time: "", id: 3 },
  { procedure: "", time: "", id: 4 },
  { procedure: "", time: "", id: 5 },
  { procedure: "", time: "", id: 6 },
  { procedure: "", time: "", id: 7 },
];

const FormPage2 = ({ data, setData, checkValues }) => {
  const [allergyHistoryQuestions, setAllergyHistoryQuestions] = useState(
    allergyHistoryQuestionsArr
  );
  const [familyHistory, setFamilyHistory] = useState(familyHistoryArr);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeOther = (event, field) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    setData((prevState) => ({
      ...prevState,
      [field]: {
        ...prevState[field],
        [name]: newValue,
      },
    }));
  };

  const handleSmokerChange = (event, field1, field2) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [field1]: {
        ...prevState[field1],
        [field2]: {
          ...prevState[field1][field2],
          [name]: value,
        },
      },
    }));
  };

  const handleChangeMedications = (event, index) => {
    const { name, value } = event.target;
    let oldList = [...data.medications.list];
    oldList[index] = value
    setData((prevState) => ({
      ...prevState,
      medications: {
        ...prevState.medications,
        list: oldList,
      },
    }));
  };

  const handleChangeAllergies = (event, index) => {

    const { name, value } = event.target;
    let oldList = [...data.allergies.medications_allergic_to];
    oldList[index] = value

    setData((prevState) => ({
      ...prevState,
      allergies: {
        ...prevState.allergies,
        medications_allergic_to: oldList,
      },
    }));
  };


  const handleChangeAllergy = (index, event) => {
    const { name, value } = event.target;

    const updatedQuestions = [...allergyHistoryQuestions];
    updatedQuestions[index] = {
      ...updatedQuestions[index],
      [name]: value,
    };

    setAllergyHistoryQuestions(updatedQuestions);
  };

  const handleFamilyHistoryChange = (index, event) => {
    const { name, value } = event.target;

    setFamilyHistory((prevFamilyHistory) => {
      const updatedFamilyHistory = [...prevFamilyHistory];
      updatedFamilyHistory[index] = {
        ...updatedFamilyHistory[index],
        relationship: value,
      };
      return updatedFamilyHistory;
    });
  };

  const hasNoEmptyValues = (obj) => {
    for (const key in obj) {
      if (!obj[key]) {
        return false;
      }
    }
    return true;
  }


  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      surgery_history: {
        ...prevState.surgery_history,
        procedure: [...allergyHistoryQuestions],
      },
    }));

  }, [allergyHistoryQuestions, data?.surgery_history?.none]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      family_history: {
        ...prevState.family_history,
        conditions_relationships: [...familyHistory],
      },
    }));




  }, [familyHistory, data?.family_history?.none]);


  return (
    <div className="formPage2Main flex">
      <div className="formSection1">
        <div className="sectionHeightWeight flex justify-content-between">
          <div className="sectionHeightWeightBlanks flex align-items-start w-50 mt-1">
            <div className="sectionHeightWeightInner endingItem">
              <h3>What is your Weight?</h3>
              <p className="italics">¿Cuál es su altura? </p>
            </div>
            <input
              type="text"
              name="height"
              onChange={(e) => handleChange(e)}
              value={data.height}

              className={`heightWeightInput ${checkValues && !data?.height ? "missingInputField" : ""}`}
            />
          </div>
          <div className="sectionHeightWeightBlanks flex align-items-start w-50 mt-1">
            <div className="sectionHeightWeightInner endingItem">
              <h3>What is your Height?</h3>
              <p className="italics">¿Cuál es su altura? </p>
            </div>
            <input
              type="text"
              name="weight"
              onChange={(e) => handleChange(e)}
              value={data.weight}
              className={`heightWeightInput ${checkValues && !data?.weight ? "missingInputField" : ""}`}
            />
          </div>
        </div>
        <div className="divider"></div>

        <div className={`medicationsSection ${data.medications.none == true ? "disabledSection" : ""}`} >
          <div>
            <div className="flex">
              <div>
                <h3 className="heading">Medications</h3>
                <p className="italics">MEDICAMENTOS</p>
              </div>
              <div className="medicationsSectionCheckbox activeChild">
                <input
                  type="checkbox"
                  name="none"
                  id="none_medics"
                  checked={data.medications.none}
                  onChange={(e) => handleChangeOther(e, "medications")}
                />
                <label htmlFor="none_medics">None</label>
                <p className="italics">Ninguno</p>
              </div>
            </div>
            <div className="medicationsSectionInfo">
              <ul>
                <li>
                  Please list all the medications you are taking, including
                  supplements (attach list as needed).
                </li>
              </ul>
              <p className="italics">
                {" "}
                Escriba todos los medicamentos que está tomando, incluidos los
                suplementos. (adjunte a lista según sea necesario)
              </p>
            </div>
          </div>
          <div
            className={`medicationsSectionDetail ${checkValues && !data.medications.none && !data?.medications?.list[0]
              ? "missingField"
              : ""
              }`}

          >
            <input
              type="text"
              name="medication1"
              className="specialInput "
              onChange={(event) => handleChangeMedications(event, 0)}
            />
            <input
              type="text"
              className="specialInput "
              name="medication2"
              onChange={(event) => handleChangeMedications(event, 1)}
            />
            <input
              type="text"
              className="specialInput "
              name="medication3"
              onChange={(event) =>
                handleChangeMedications(event, 2)
              }
            />
          </div>
          <div className="divider"></div>
        </div>

        <div className={`allergiesSection ${data?.allergies?.none == true ? "disabledSection" : ""}`}>
          <div>
            <div className="flex">
              <div>
                <h3 className="heading">Allergies</h3>
                <p className="italics">ALERGIAS</p>
              </div>

              <div className="medicationsSectionCheckbox activeChild">
                <input
                  type="checkbox"
                  name="none"
                  id="none_allergies"
                  checked={data.allergies.none}
                  onChange={(e) => handleChangeOther(e, "allergies")}
                />
                <label htmlFor="none_allergies">None</label>
                <p className="italics">Ninguno</p>
              </div>
            </div>
            <div>
              <div
                className="allergiesQuiz1 flex align-items-center"
              >
                <div>
                  <p>Are you allergic to:</p>
                  <p className="italics">¿Eres alérgico a</p>
                </div>
                <div
                  className={` flex justify-content-between allergiesQuiz1Choices ${checkValues && !data.allergies.none && !data?.allergies?.allergic_to ? "missingField" : ""}`}
                >
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="allergic_to"
                        className="radio-custom"
                        id={"latex"}
                        value={"latex"}
                        onChange={(e) => handleChangeOther(e, "allergies")}
                      />
                      <label htmlFor={"latex"}>Latex</label>
                    </div>
                    <p className="italics checkboxTranslate">Látex</p>
                  </div>
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="allergic_to"
                        className="radio-custom"
                        id={"contrast_dye"}
                        value={"contrast_dye"}
                        onChange={(e) => handleChangeOther(e, "allergies")}
                      />
                      <label htmlFor={"contrast_dye"}>Contrast Dye</label>
                    </div>
                    <p className="italics checkboxTranslate">
                      Tinte de contraste
                    </p>
                  </div>
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="allergic_to"
                        className="radio-custom"
                        id={"iodine"}
                        value={"iodine"}
                        onChange={(e) => handleChangeOther(e, "allergies")}
                      />
                      <label htmlFor={"iodine"}>Iodine</label>
                    </div>
                    <p className="italics checkboxTranslate">Yodo</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="allergiesSectionInfo">
              <ul>
                <li>
                  Please list any medications you are allergic to and how each
                  affects you.
                </li>
              </ul>
              <p className="italics">
                Escriba todos los medicamentos que está tomando, incluidos los
                suplementos. (adjunte a lista según sea necesario)
              </p>
            </div>
          </div>
          <div

            className={`allergiesSectionDetail ${checkValues && !data.allergies.none && !data?.allergies?.medications_allergic_to[0]
              ? "missingField"
              : ""
              }`}
          >
            <input
              className="specialInput "
              type="text"
              name="medication1"
              onChange={(event) => handleChangeAllergies(event, 0)}
            />
            <input
              type="text"
              className="specialInput "
              name="medication2"
              onChange={(event) => handleChangeAllergies(event, 1)}
            />
            <input
              type="text"
              className="specialInput "
              name="medication3"
              onChange={(event) => handleChangeAllergies(event, 2)}
            />
          </div>
          <div className="divider"></div>
        </div>

        <div className={`surgerySection ${data?.surgery_history?.none == true ? "disabledSection" : ""}`}>
          <div>
            <div className="flex">
              <div>
                <h3 className="heading">Surgery History</h3>
                <p className="italics">HISTORIA DE CIRUGÍA</p>
              </div>

              <div className="surgerySectionCheckbox activeChild">
                <input
                  type="checkbox"
                  name="none"
                  id="none_surgey"
                  checked={data.surgery_history.none}
                  onChange={(e) => handleChangeOther(e, "surgery_history")}
                />
                <label htmlFor="none_surgey">None</label>
                <p className="italics">Ninguno</p>
              </div>
            </div>
            <div>
              <div className="surgerySectionQuiz1 flex align-items-center">
                <div>
                  <p>Have you ever had problems with anesthesia?</p>
                  <p className="italics">
                    ¿Alguna vez ha tenido problemas con la anestesia?
                  </p>
                </div>
                <div className={` ${checkValues && !data.surgery_history.none && !data?.surgery_history?.have_anesthesia ? "missingField" : ""} flex socialHistorySectionCheckbox `}>
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="have_anesthesia"

                        className={`radio-custom `}
                        id={"yes"}
                        value={"yes"}
                        onChange={(e) =>
                          handleChangeOther(e, "surgery_history")
                        }
                      />
                      <label htmlFor={"yes"}>Yes</label>
                    </div>
                    <p className="italics checkboxTranslate">Si</p>
                  </div>
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="have_anesthesia"
                        className="radio-custom"
                        id={"no"}
                        value={"no"}
                        onChange={(e) =>
                          handleChangeOther(e, "surgery_history")
                        }
                      />
                      <label htmlFor={"no"}>No</label>
                    </div>
                    <p className="italics checkboxTranslate">No</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="surgerySectionInfo">
              <div className="flex align-items-center">
                <div style={{ width: "150px" }}>
                  <p>Please explain:</p>
                  <p className="italics">Por favor explique</p>
                </div>
                <input
                  type="text"
                  name="explain"
                  className={`radio-custom ${checkValues && !data.surgery_history.none && !data?.surgery_history?.explain ? "missingInputField" : ""}`}
                  value={data.surgery_history.explain}
                  onChange={(e) => handleChangeOther(e, "surgery_history")}

                />
              </div>
            </div>
          </div>
          <div className="surgerySectionDetail mt-1">
            <div className="surgerySectionHead flex">
              <div>
                <h3>PROCEDURE</h3>
              </div>
              <div>
                <h3>DATE (MO/YR)</h3>
              </div>
            </div>
            {allergyHistoryQuestions.map((item, index) => {
              return (
                <div key={index} className={`flex `}>
                  <input
                    name="procedure"
                    className={`historySectionCondtion w-50 ${checkValues && index === 0 && !data?.surgery_history?.none && !data?.surgery_history?.procedure[index].procedure ? "missingField" : ""}`}
                    onChange={(event) => handleChangeAllergy(index, event)}
                  />
                  <input
                    name="time"
                    className={`historySectionRelationship w-50 ${checkValues && index === 0 && !data?.surgery_history?.none && !data?.surgery_history?.procedure[index].time ? "missingField" : ""}`}
                    onChange={(event) => handleChangeAllergy(index, event)}
                  />
                </div>
              );
            })}
          </div>
          <div className="divider"></div>
        </div>
      </div>

      <div className="formSection2">
        <div className={`familyHistorySection ${data?.family_history?.none == true ? "disabledSection" : ""}  `}>
          <div>
            <div className="flex">
              <div>
                <h3 className="heading">FAMILY HISTORY</h3>
                <p className="italics">HISTORIA FAMILIAR</p>
              </div>

              <div className="familyHistorySectionCheckbox activeChild">
                <input
                  type="checkbox"
                  name="none"
                  id="none_family_history"
                  checked={data.family_history.none}
                  onChange={(e) => handleChangeOther(e, "family_history")}
                />
                <label htmlFor="none_family_history">None</label>
                <p className="italics">Ninguno</p>
              </div>
            </div>
            <div>
              <p className="familyHistorySectionText">
                Please list any family history of your{" "}
                <b>Father, Mother, Siblings, Children</b> and{" "}
                <b>Grandparents</b>
              </p>
              <p className="italics">
                {" "}
                Escriba todos los medicamentos que está tomando, incluidos los
                suplementos. (adjunte a lista según sea necesario)
              </p>
            </div>
          </div>
          <div className="historySectionDetail">
            <div className="historySectionHead flex">
              <div>
                <h3>CONDITION</h3>
                <p className="italics">CONDICIÓN</p>
              </div>
              <div>
                <h3>RELATIONSHIP</h3>
                <p className="italics">RELACIÓN</p>
              </div>
            </div>
            {familyHistory.map((item, index) => {
              return (
                <div key={index} className="flex">
                  <div
                    className="historySectionCondtion"
                    style={{ width: "50%" }}
                  >
                    <p>{item.condition}</p>
                    <p className="italics">{item.translation}</p>
                  </div>
                  <input
                    name="relation"
                    className="historySectionRelationship"
                    className={`historySectionRelationship ${checkValues && !data?.family_history?.none && !data?.family_history?.conditions_relationships[index].relationship ? "missingField" : ""}`} style={{ width: "50%" }}
                    value={item.relationship}
                    onChange={(event) =>
                      handleFamilyHistoryChange(index, event)
                    }
                  />
                </div>
              );
            })}
          </div>
          <div className="divider"></div>
        </div>
        <div className={`socialHistorySection `}>
          <div className={`${data?.social_history?.smoker_type === "non_smoker" ? "disabledSection" : ""}`}>
            <div>
              <div className="flex">
                <div>
                  <h3 className="heading">SOCIAL HISTORY:</h3>
                  <p className="italics">HISTORIA SOCIAL</p>
                </div>
                {/* <div className="socialHistorySectionCheckbox activeChild">
                <input
                  type="checkbox"
                  name="none"
                  id="none_social_history"
                  checked={data.social_history.none}
                  onChange={(e) => handleChangeOther(e, "social_history")}
                />
                <label htmlFor="none_social_history">None</label>
                <p className="italics">Ninguno</p>
              </div> */}
              </div>
              <div>
                <div className="socialHistoryQuiz flex align-items-center">
                  <div>
                    <p>Are you a:</p>
                    <p className="italics">Eres un</p>
                  </div>
                  <div className={`${checkValues && !data?.social_history?.smoker_type ? "missingField" : ""} flex felx-wrap `}>
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name="smoker_type"
                          className="radio-custom"
                          id={"non_smoker"}
                          value={"non_smoker"}
                          onChange={(e) => handleChangeOther(e, "social_history")}
                        />
                        <label htmlFor={"non_smoker"}>Non Smoker</label>
                      </div>
                      <p className="italics checkboxTranslate">No Fuma</p>
                    </div>
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name="smoker_type"
                          className="radio-custom"
                          id={"former_smoker"}
                          value={"former_smoker"}
                          onChange={(e) => handleChangeOther(e, "social_history")}
                        />
                        <label htmlFor={"former_smoker"}>Former Smoker</label>
                      </div>
                      <p className="italics checkboxTranslate">Ex-fumador</p>
                    </div>
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name="smoker_type"
                          className="radio-custom"
                          id={"current_smoker"}
                          value={"current_smoker"}
                          onChange={(e) => handleChangeOther(e, "social_history")}
                        />
                        <label htmlFor={"current_smoker"}>Current Smoker</label>
                      </div>
                      <p className="italics checkboxTranslate">Actual fumador</p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`former_smoker ${data?.social_history?.smoker_type === "current_smoker" ? "disabledSection" : ""}`}

              >
                <p>
                  If <b>"Former Smoker"</b>
                </p>
                <p className="italics">Si ‘Ex fumador’</p>
                <div className="flex align-items-center socialHistorySmoker">
                  <div style={{ width: "250px" }}>
                    <p>How long did you smoke?</p>
                    <p className="italics">¿Cuándo dejaste?</p>
                  </div>
                  <input
                    type="text"
                    name="how_long_did_you_somke"
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "former_smoker"
                      )
                    }
                    className={`${checkValues && data?.social_history?.smoker_type === "former_smoker" && !data?.social_history?.former_smoker?.how_long_did_you_somke ? "missingInputField" : ""}`}
                  />
                </div>
                <div className="flex align-items-center socialHistorySmoker">
                  <div style={{ width: "250px", marginTop: "1em" }}>
                    <p>When did you quit?</p>
                    <p className="italics">¿Cuánto tiempo fumaste?</p>
                  </div>
                  <input
                    type="text"
                    name="when_did_you_quit"
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "former_smoker"
                      )
                    }
                    className={`${checkValues && data?.social_history?.smoker_type === "former_smoker" && !data?.social_history?.former_smoker?.when_did_you_quit ? "missingInputField" : ""}`}
                  />
                </div>
              </div>
              <div
                className={`current_smoker  ${data?.social_history?.smoker_type === "former_smoker" ? "disabledSection" : ""}`}
                style={{ marginTop: "1em" }}
              >
                <p>
                  If <b>"Current Smoker"</b>
                </p>
                <p className="italics">Si ‘Fumador actual’</p>
                <div className="flex align-items-center socialHistorySmoker">
                  <div style={{ width: "470px" }}>
                    <p>How many packs per day do you smoke?</p>
                    <p className="italics">¿Cuántos paquetes al día fumas?</p>
                  </div>
                  <input
                    type="text"
                    name="how_many_packs_per_day_do_you_smoke"
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "current_smoker"
                      )
                    }
                    className={`${checkValues && data?.social_history?.smoker_type === "current_smoker" && !data?.social_history?.current_smoker?.how_many_packs_per_day_do_you_smoke ? "missingInputField" : ""}`}
                  />
                </div>
                <div className="flex align-items-center socialHistorySmoker">
                  <div style={{ width: "250px", marginTop: "1em" }}>
                    <p>How long have you smoked?</p>
                    <p className="italics"> ¿Cuántos tiempo has fumado?</p>
                  </div>
                  <input
                    type="text"
                    name="how_long_have_you_smoked"
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "current_smoker"
                      )
                    }
                    className={`${checkValues && data?.social_history?.smoker_type === "current_smoker" && !data?.social_history?.current_smoker?.how_long_have_you_smoked ? "missingInputField" : ""}`}
                  />
                </div>

                <div
                  className="socialHistoryQuiz2 flex align-items-center"
                  style={{ marginTop: "1em" }}
                >
                  <div className="mr-1">
                    <p>Are you interested in quitting?</p>
                    <p className="italics">¿Estás interesado en dejar de fumar?</p>
                  </div>
                  <div
                    className={`flex socialHistorySectionCheckbox ${checkValues && data?.social_history?.smoker_type === "current_smoker" && !data?.social_history?.current_smoker?.interested_in_quitting ? "missingField" : ""}`}
                  >
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name="interested_in_quitting"
                          className="radio-custom"
                          id={"interseted_quiting_yes"}
                          value={"yes"}
                          onChange={(e) =>
                            handleSmokerChange(
                              e,
                              "social_history",
                              "current_smoker"
                            )
                          }
                        />
                        <label htmlFor={"interseted_quiting_yes"}>Yes</label>
                      </div>
                      <p className="italics checkboxTranslate">Si</p>
                    </div>
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name="interested_in_quitting"
                          className="radio-custom"
                          id={"interseted_quiting_no"}
                          value={"no"}
                          onChange={(e) =>
                            handleSmokerChange(
                              e,
                              "social_history",
                              "current_smoker"
                            )
                          }
                        />
                        <label htmlFor={"interseted_quiting_no"}>No</label>
                      </div>
                      <p className="italics checkboxTranslate">No</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="socialHistoryQuiz2 flex align-items-center"
            style={{ marginTop: "1em" }}
          >
            <div className="mr-1">
              <p>Do you drink?</p>
              <p className="italics">¿Bebes alcohol?</p>
            </div>
            <div
              className={`flex socialHistorySectionCheckbox ${checkValues && !data?.social_history?.current_smoker?.do_you_drink ? "missingField" : ""}`}
            >
              <div>
                <div className="radio-main">
                  <input
                    type="radio"
                    name="do_you_drink"
                    className="radio-custom"
                    id={"drink_yes"}
                    value={"yes"}
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "current_smoker"
                      )
                    }
                  />
                  <label htmlFor={"drink_yes"}>Yes</label>
                </div>
                <p className="italics checkboxTranslate">Si</p>
              </div>
              <div>
                <div className="radio-main">
                  <input
                    type="radio"
                    name="do_you_drink"
                    className="radio-custom"
                    id={"drink_no"}
                    value={"no"}
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "current_smoker"
                      )
                    }
                  />
                  <label htmlFor={"drink_no"}>No</label>
                </div>
                <p className="italics checkboxTranslate">No</p>
              </div>
            </div>
          </div>
          <div
            className={`socialHistoryQuiz2 flex align-items-center ${data?.social_history?.current_smoker?.do_you_drink && data?.social_history?.current_smoker?.do_you_drink === "no" ? "disabledSection" : ""}`}
            style={{ marginTop: "1em" }}
          >
            <div className="mr-1">
              <p>
                If <b>‘Yes’</b>: How often do you drink?
              </p>
              <p className="italics">
                En caso afirmativo, ¿con qué frecuencia bebes?
              </p>
            </div>
            <div
              className={`flex socialHistorySectionCheckbox ${checkValues && data?.social_history?.current_smoker?.do_you_drink !== "no" && !data?.social_history?.current_smoker?.how_often_do_you_drink ? "missingField" : ""}`}
            >
              <div>
                <div className="radio-main">
                  <input
                    type="radio"
                    name="how_often_do_you_drink"
                    className="radio-custom"
                    id={"Daily"}
                    value={"Daily"}
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "current_smoker"
                      )
                    }
                  />
                  <label htmlFor={"Daily"}>Daily</label>
                </div>
                <p className="italics checkboxTranslate">
                  Pocos por semana/mesr
                </p>
              </div>
              <div>
                <div className="radio-main">
                  <input
                    type="radio"
                    name="how_often_do_you_drink"
                    className="radio-custom"
                    id={"Few"}
                    value={"Few"}
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "current_smoker"
                      )
                    }
                  />
                  <label htmlFor={"Few"}>Few per week/mo</label>
                </div>
                <p className="italics checkboxTranslate">Diario</p>
              </div>
              <div>
                <div className="radio-main">
                  <input
                    type="radio"
                    name="how_often_do_you_drink"
                    className="radio-custom"
                    id={"Rarely"}
                    value={"Rarely"}
                    onChange={(e) =>
                      handleSmokerChange(
                        e,
                        "social_history",
                        "current_smoker"
                      )
                    }
                  />
                  <label htmlFor={"Rarely"}>Rarely</label>
                </div>
                <p className="italics checkboxTranslate">Rara vez</p>
              </div>
            </div>
          </div>

          <div className="divider"></div>
        </div>
        <div className="drugUseSection">
          <div>
            <div className="">
              <div>
                <h3 className="heading">RECREATIONAL DRUG USE: </h3>
                <p className="italics">USO RECREATIVO DE DROGAS</p>
              </div>
            </div>
            <div>
              <div className="">
                <div className={`flex felx-wrap ${checkValues && !data?.recreational_drug_use?.used ? "missingField" : ""}`}>
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="used"
                        className="radio-custom"
                        id={"recreational_never"}
                        value={"Never"}
                        onChange={(e) =>
                          handleChangeOther(e, "recreational_drug_use")
                        }
                      />
                      <label htmlFor={"recreational_never"}>Never</label>
                    </div>
                    <p className="italics checkboxTranslate">Nunca</p>
                  </div>
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="used"
                        className="radio-custom"
                        id={"recreational_previous"}
                        value={"Previous"}
                        onChange={(e) =>
                          handleChangeOther(e, "recreational_drug_use")
                        }
                      />
                      <label htmlFor={"recreational_previous"}>Previous</label>
                    </div>
                    <p className="italics checkboxTranslate">Anterior</p>
                  </div>
                  <div>
                    <div className="radio-main">
                      <input
                        type="radio"
                        name="used"
                        className="radio-custom"
                        id={"recreational_current"}
                        value={"Current"}
                        onChange={(e) =>
                          handleChangeOther(e, "recreational_drug_use")
                        }
                      />
                      <label htmlFor={"recreational_current"}>Current</label>
                    </div>
                    <p className="italics checkboxTranslate">Presente</p>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={`${data.recreational_drug_use.used === "Never" ? "disabledSection" : ""}`}>
                <div className="flex align-items-center socialHistorySmoker">
                  <div style={{ width: "250px" }}>
                    <p>Drugs</p>
                    <p className="italics">Droga</p>
                  </div>
                  <input
                    type="text"
                    name="drug"
                    value={data.recreational_drug_use.drug}
                    onChange={(e) =>
                      handleChangeOther(e, "recreational_drug_use")
                    }
                    className={`${checkValues && data?.recreational_drug_use?.used && data?.recreational_drug_use?.used !== "Never" && !data?.recreational_drug_use?.drug ? "missingInputField" : ""}`}
                  />
                </div>
                <div className="flex align-items-center socialHistorySmoker">
                  <div style={{ width: "250px", marginTop: "1em" }}>
                    <p>Last Used: </p>
                    <p className="italics">Utilizado por última vez</p>
                  </div>
                  <input
                    type="text"
                    name="last_used"
                    value={data.recreational_drug_use.last_used}
                    onChange={(e) =>
                      handleChangeOther(e, "recreational_drug_use")
                    }
                    className={`${checkValues && data?.recreational_drug_use?.used && data?.recreational_drug_use?.used !== "Never" && !data?.recreational_drug_use?.last_used ? "missingInputField" : ""}`}
                  />
                </div>
              </div>
              <div
                className={`${checkValues && data?.patient_info?.sex === "Female" && !data?.recreational_drug_use?.female_only ? "missingField" : ""} border drugUseSectionFemaleOnly`}>
                <h3>Female Patients Only</h3>
                <p className="italics"> Solo pacientes femeninos</p>
                <div className="femaleOnlyQuiz flex align-items-center">
                  <div>
                    <p>
                      Are you now or is there a chance that you are pregnant?
                    </p>
                    <p className="italics">
                      {" "}
                      ¿Está usted ahora o existe la posibilidad de que esté
                      embarazada?
                    </p>
                  </div>

                  <div className="flex drugUseSectionCheckbox">
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name="female_only"
                          className="radio-custom"
                          id={"female_yes"}
                          value={"yes"}
                          onChange={(e) =>
                            handleChangeOther(e, "recreational_drug_use")
                          }
                        />
                        <label htmlFor={"female_yes"}>Yes</label>
                      </div>
                      <p className="italics checkboxTranslate">Si</p>
                    </div>
                    <div>
                      <div className="radio-main">
                        <input
                          type="radio"
                          name="female_only"
                          className="radio-custom"
                          id={"female_no"}
                          value={"no"}
                          onChange={(e) =>
                            handleChangeOther(e, "recreational_drug_use")
                          }
                        />
                        <label htmlFor={"female_no"}>No</label>
                      </div>
                      <p className="italics checkboxTranslate">No</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
        </div>
      </div>
    </div >
  );
};

export default FormPage2;
