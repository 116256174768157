import React, { useState } from 'react'
import { Alert } from "antd";
import App from './App';
const AppParent = () => {
    const [key, setKey] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const handleAlert = () => {
        setShowAlert(true);

        // Automatically hide the alert after 3 seconds
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    };
    const handleSubmit = () => {
        handleAlert();
        setKey(key + 1);
    };
    return (
        <>

            <App key={key} handleSubmit={handleSubmit} />
            {showAlert && (
                <div
                    style={{
                        position: 'fixed',
                        top: '10%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 9999,
                        width: '300px',
                    }}
                >
                    <Alert
                        message="Success"
                        description="Data submitted successfully!"
                        type="success"
                        showIcon
                        closable
                        onClose={() => setShowAlert(false)}
                    />
                </div>
            )}
        </>
    )
}

export default AppParent;