import React, { useEffect } from "react";
import "./FormPage8.css";
import { Col, DatePicker, Row } from "antd";
import Footer from "../FormPage6/FormSection9";
import "../FormSections.css";
import SignatureFeature from "../../SignatureField/SignatureField";
const FormPage8 = ({ data, setData, checkValues }) => {



  const handleChange = (e, Name, noValue) => {
    const key = noValue ? Name : e?.target?.name;
    const value = noValue ? e : e?.target?.value;
    setData({
      ...data,
      procedures_in_office: {
        ...data?.procedures_in_office,
        [key]: value,
      },
    });
  };
  return (
    <div className="formPage8Main">
      <h3 className="heading">PROCEDURES IN OFFICE</h3>
      <p>
        Please be aware that certain procedures performed in our office are not
        included in the standard office visit. These procedures will be billed
        separately and in addition to office visit charges. We have become aware
        that some insurance carrier are classifying these procedures as
        “surgery” and applying the charges to your calendar year deductible. The
        result may be insurance payment for an office visit but NOT a procedure.
        In such cases, payment for the procedure will be due from the patient.
        Be assured that we are following accepted billing and coding guidelines.{" "}
      </p>
      <p className="marginTop3">
        The providers of Enticare PC only perform these procedures when deemed
        medically necessary to best diagnose and treat our patients.{" "}
        <u>
          If you are presenting with a sinus, throat/voice complaint, symptoms
          of allergies, or hearing loss there is a good chance the provider will
          need to perform one or more of the following procedures
        </u>
      </p>
      <p className="marginTop3">
        Examples of in-office procedures include but are not limited to:
      </p>
      <ul>
        <li>CPT-31575 Flexible Laryngoscopy</li>
        <p>
          This procedure involves passing a long thin flexible fiber-optic scope
          through the nasal cavity and into the throat. The fiber-optic scope
          enables the physician to visualize areas of the throat not readily
          seen using laryngeal mirrors.
        </p>
        <li>CPT-31231 Nasal Endoscopy</li>
        <p>
          This procedure uses the flexible or rigid scope attached to a light
          source to view areas of the nasal cavities that cannot be viewed by
          the physician using the standard nasal speculum and head mirror.
        </p>
        <li>CPT-31237 Nasal Endoscopy with Debridement or Biopsy</li>
        <p>
          This is the same procedure as above with removal of crusting or
          tissue.
        </p>
        <li>CPT-92511 Flexible Nasopharyngoscopy</li>
        <p>
          This involves examining both the tissues of the nasal passages AND the
          pharynx and larynx.{" "}
        </p>
        <li>CPT-95004 Allergy Test </li>
        <p>
          This procedure is an Allergy test that can help confirm or rule out
          allergies and consequently reduce adverse reactions and limit
          unnecessary avoidance and medications.{" "}
        </p>
        <li>CPT 92567- Tympanometry</li>
        <li>CPT 92557- Audiometry</li>
        <li>CPT 92588- Otoacoustic Emissions (OAE)</li>
        <p>
          These are the codes used for audiology services. Tympanometry is used
          to tell the pressure of the ear drums. Audiometry and OAE is
          considered a basic hearing test, it is used to tell if hearing is
          normal.{" "}
        </p>
      </ul>
      <p className="marginTop4">
        Please contact your insurance provider to verify benefits and coverage
        information prior to having any services rendered.
      </p>
      <Row>
        <Col sm={10} xs={24} className="marginTop7 textCenter">
          <SignatureFeature
            name={"office_procedures_signature"}
            onChange={handleChange}
            value={data?.procedures_in_office?.office_procedures_signature}
          />
          <div className={`marginTop borderTop signTextOver ${checkValues && !data?.procedures_in_office?.office_procedures_signature?.signature ? "missingFieldSpecial" : ""}`}>
            Signature of Patient, Parent, or Legal Guardian
          </div>
        </Col>
        <Col sm={7} xs={0}></Col>
        <Col sm={7} xs={24} className="marginTop7 textCenter signSibling">
          <input
            name="procedure_relation"
            type="text"

            className={`w-100 ${checkValues && !data?.procedures_in_office?.procedure_relation ? "missingInputField" : ""}`}
            onChange={(e) => handleChange(e, "procedure_relation")}
            value={data?.procedures_in_office?.procedure_relation}
          />
          <div className=" marginTop"> Relationship</div>
        </Col>
      </Row>
      <Row>
        <Col sm={10} xs={24} className="marginTop7 textCenter">
          <input
            name="procedure_print_name"
            type="text"
            onChange={(e) => handleChange(e, "procedure_print_name")}
            value={data?.procedures_in_office?.procedure_print_name}

            className={`w-100 ${checkValues && !data?.procedures_in_office?.procedure_print_name ? "missingInputField" : ""}`}
          />
          <div className=" marginTop">Print Name</div>
        </Col>
        <Col sm={7} xs={0}></Col>
        <Col sm={7} xs={24} className="marginTop7 textCenter">
          <DatePicker

            className={`specialDatePicker fs2dateStyle ${checkValues && !data?.procedures_in_office?.procedure_date ? "missingInputField" : ""}`}
            name="procedure_date"
            onChange={(date, dateString) =>
              handleChange(dateString, "procedure_date", true)
            }
          />
          <div className=" marginTop"> Date</div>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default FormPage8;
